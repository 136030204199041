import { TabContext, TabList } from "@mui/lab";
import { Box, Card, InputBase, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, styled } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import '../ActivityLog/ActivityLog.css'
import CircleIcon from '@mui/icons-material/Circle';
import PhonePausedOutlinedIcon from '@mui/icons-material/PhonePausedOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import SearchIcon from "@mui/icons-material/Search";
import { getActivityLogDetails } from "../../../services/api-service";
import { WIDGET_REFRESH_INTERVAL } from "../../../utils/constants";

let interval: any;

const Search = styled("div")(({ theme }) => ({
    borderRadius: "8px",
    border: "1px solid #d9d9d9",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "#99A0A8",
    "& .MuiInputBase-input": {
        fontSize: '14px',
        height: '30px',
        color: '#0a0808'
    },
}));


const TableHeader = (props: any) => {
    const onSearchChange = (e: any) => {
        props.handleSearchChange(e.target.value);
    }

    return (
        <div className="card-header border-none">
            <div className="card-title">
                Activity Log
            </div>
            <Search sx={{ display: 'flex', maxHeight: '34px', padding: '8px', marginRight: '5px' }} className='headerItem'>
                <SearchIcon sx={{
                    color: "#616161",
                    alignSelf: 'center',
                    marginRight: '8px',
                    marginLeft: '10px'
                }} />
                <StyledInputBase
                    placeholder={'Search'}
                    inputProps={{ "aria-label": "search" }}
                    value={props.searchValue}
                    onChange={onSearchChange}
                    id='search-text-box'

                />
            </Search>
        </div>
    );
}

function ActivityLog() {
    type ActivityData = {
        agentId: string;
        agentName: string;
        category: string;
        currentTime: string;
        since: string;
    };

    const [logType, setLogType] = useState('all');
    const [activityData, setActivityData] = useState<ActivityData[]>([]);
    const [searchValue, setSearchValue] = useState('');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setLogType(newValue);
    };

    const handleSearchChange = (value: any) => {
        setSearchValue(value);
    }

    const DISPLAY_LABELS: any = {
        hold: 'has been on hold for',
        busy: 'has been on a :TYPE for',
        acw: 'has been in ACW for',
        paused: 'has exceeded :REASON by',
    };

    const formattedText = (row: ActivityData, type: String) => {
        const [rowCategory, pauseReason] = getRowCategoryReason(row);
        const rowText = getRowText(rowCategory, pauseReason);
        return (
            <div>
                {type === 'bold' &&
                    <>
                        <b className="boldStyle"> {row.agentName}</b> {rowText} <b className="boldStyle">{row.since}</b>
                    </>
                }
                {type === 'normal' && (
                    <>{row.agentName} {rowText} {row.since}</>
                )}

            </div>
        );
    };

    const agentDetails = async () => {
        const agentData = await getActivityLogDetails();
        setActivityData(agentData);
    };

    const getRowCategoryReason = (row: ActivityData) => {
        let rowCategory = row.category;
        let reason = '';

        if (rowCategory.toLowerCase().includes('paused')) {
            const parts = rowCategory.split(':');
            if (parts.length > 1) {
                reason = parts[1].trim();
                rowCategory = parts[0].trim();
            }
        }else if (rowCategory.toLowerCase().includes('busy')) {
            const parts = rowCategory.split(':');
            if (parts.length > 1) {
                reason = parts[0].trim();
                rowCategory = parts[1].trim();
            }
        }
        return [rowCategory, reason];
    }

    const getRowText = (rowCategory: string, reason: string) => {
        let rowText = DISPLAY_LABELS[rowCategory.toLowerCase()];
        if (rowCategory.toLowerCase() === 'paused') {
            rowText = rowText.replace(':REASON', reason);
        }else if (rowCategory.toLowerCase() === 'busy') {
            rowText = rowText.replace(':TYPE', reason);
        }
        return rowText;
    }

    const iconStyle = { fontSize: "22px", color: "#616161", marginRight: '17%', marginBottom: '-3%' }
    const getDisplayIcon = (category: string) => {
        const lowerCaseCategory = category.toLowerCase();

        if (lowerCaseCategory.includes('hold')) {
            return (
                <PhonePausedOutlinedIcon style={iconStyle} />
            );
        } else if (lowerCaseCategory.includes('acw')) {
            return (
                <LocalOfferOutlinedIcon style={iconStyle} />
            );
        }
        else if (lowerCaseCategory.includes('busy')) {
            return (
                <PhoneInTalkOutlinedIcon style={iconStyle} />
            );
        } else if (lowerCaseCategory.includes('paused')) {
            return (
                <TimerOutlinedIcon style={iconStyle} />

            );
        }
        return null;
    };

    const filteredData = useMemo(() => {
        return activityData.filter((row) => {
            const [rowCategory, reason] = getRowCategoryReason(row);
            const matchesLogType = logType === 'all' || logType === rowCategory.toLowerCase();
            const rowText = getRowText(rowCategory.toLowerCase(), reason);
            const matchesSearch = searchValue == '' || (row.agentName.toLowerCase().includes(searchValue.toLowerCase()) || rowText.toLowerCase().includes(searchValue.toLowerCase()) || row.since.toLowerCase().includes(searchValue.toLowerCase()));
            return matchesLogType && matchesSearch;
        });
    }, [logType, activityData, searchValue]);

    useEffect(() => {
        agentDetails();
    }, [])

    React.useEffect(() => {
		return () => {
			clearInterval(interval);
		};
	}, []);

	React.useEffect(() => {
		agentDetails();
		if (interval) {
			clearInterval(interval);
		}

		interval = setInterval(() => agentDetails(), WIDGET_REFRESH_INTERVAL.ACTIVITY_LOG);
	}, []);

    return (
        <Card className="customCard">
            <div className="cardStyle">
                <TableHeader handleSearchChange={handleSearchChange} searchValue={searchValue} />
                <div style={{ height: 'calc(100% - 80px)' }}>
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={logType}>
                            <Box sx={{ border: 1, borderColor: 'divider', borderTop: 0, overflowX: "auto" }} className="custom-scrollbar">
                                <TabList onChange={handleChange} className="customTabs custom-scrollbar" sx={{ "& .MuiTabs-scroller": { overflowX: "auto !important" } }}>
                                    <Tab label="All" value="all" />
                                    <Tab label="Hold" value="hold" />
                                    <Tab label="ACW" value="acw" />
                                    <Tab label="Busy" value="busy" />
                                    <Tab label="Break" value="paused" />
                                </TabList>
                            </Box>
                        </TabContext>
                    </Box>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }} className='custom-table-paper-height' style={{ border: 'none', boxShadow: 'none', borderRadius: '0px' }}>
                        <TableContainer className="custom-table-scroll custom-table-maxHeight" >
                            <Table aria-label="activity table">
                                <TableBody>
                                    {(filteredData.length == 0) &&
                                        <TableRow className="tableRow">
                                            <TableCell className="tableCell"></TableCell>
                                        </TableRow>
                                    }
                                    {filteredData.map((row) => {
                                        return <TableRow className="tableRow">
                                            <TableCell className="tableCell" align="right" sx={{ paddingRight: "0px", paddingLeft: "0px", width: '70px' }}>
                                                {getDisplayIcon(row.category)}
                                                <CircleIcon sx={{ fontSize: "12px", color: "#e3e3e3", marginBottom: '4px', marginLeft: '5px' }} />
                                            </TableCell>
                                            <TableCell className="tableCell" align="left">
                                                <Tooltip title={formattedText(row, 'normal')}>
                                                    <Typography sx={{
                                                        maxWidth: "368px",
                                                        textOverflow: "ellipsis",
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden"
                                                    }}>{formattedText(row, 'bold')}</Typography>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell className="tableCell tableCellTime" align="right">{row.currentTime}</TableCell>
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </div>
            </div>
        </Card>
    );
}

export default ActivityLog;