import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import { Button, Grid, IconButton, Menu, MenuItem, TextField, Snackbar, Alert } from '@mui/material';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { OZContext } from '../../contexts/OZContext';
import { PRESET_MOCK } from '../../mocks/mock-data';
import { dashboard } from '../../services/api-service';
import '../Presets/Presets.css';
import { showNotification } from '../../utils/notificationUtils';
import { getAgentAlertMessages } from '../../utils/agentAlertsUtil';
import { getActivityLogDetails } from "../../services/api-service";
import { WIDGET_REFRESH_INTERVAL } from '../../utils/constants';
import { ActivityData } from '../../types/agentAlertsType'
import { Favorite } from '@mui/icons-material';

const presetWallboardData = {
	dashboard: {
		id: 1,
		dashboardName: 'Wallboard',
		favourite: true,
		thumbnail: `${process.env.PUBLIC_URL}/thumbnails/wallboard-preset-thumbnail.png`
	}
};

type Props = {
	onPresetClick: any;
	onPresetEdit: any;
};

    // type ActivityData = {
    //   agentId: string;
    //   agentName: string;
    //   category: string;
    //   currentTime: string;
    //   since: string;
    // };

function Presets({ onPresetClick, onPresetEdit }: Props) {
	const navigate = useNavigate();
	const { setIsLoaderOpen } = React.useContext(OZContext);
	const [presetData, setPresetData] = React.useState<any>([]);
	const [filteredPresetData, setFilteredPresetData] = React.useState<any>([presetWallboardData]);
	const [selectedPreset, setSelectedPreset] = React.useState<any>({});
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const [openSnackbar, setOpenSnackbar] = React.useState<any>(false);
	const [snackbarMessage, setSnackbarMessage] = React.useState<any>('');
	const [snackbarMessageSuccess, setSnackbarMessageSuccess] = React.useState<any>('');
	const [openSnackbarSuccess, setOpenSnackbarSuccess] = React.useState<any>(false);
	const [activityData, setActivityData] = React.useState<ActivityData[]>([]);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>, newSelectedPreset: any) => {
		event.stopPropagation();
		setSelectedPreset(newSelectedPreset);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	

	const toggleFavourite = async (event: React.MouseEvent<HTMLButtonElement>, preset: any) => {
		event.stopPropagation();
		const updatedPreset = { ...preset };
		updatedPreset.dashboard.favourite = !updatedPreset?.dashboard?.favourite;
		await dashboard('update', { metadata: JSON.stringify({ ...updatedPreset.dashboard }), id: preset.id });
		getPresetData();
	};

	const handlePresetAction = async (event: any, action: string) => {
		console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",selectedPreset);
		event.stopPropagation();
		switch (action) {
			case 'EDIT':
				onPresetEdit(selectedPreset);
				break;
			case 'DELETE':
			const response = await dashboard('delete', { id: selectedPreset.id });
			if (response.status !== 200) {
				setSnackbarMessage(`Error ${action === 'DELETE' ? 'deleting' : 'editing'} dashboard!`);
				setOpenSnackbar(true);
				return;
			}else{
				setSnackbarMessageSuccess(`Dashboard ${action === 'DELETE' ? 'deleted' : 'edited'} successfully`);
				setOpenSnackbarSuccess(true);
			}
			getPresetData();
		}
		handleClose();
	};

	const handleCloseError = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenSnackbar(false);
	};

	const handleCloseSuccess = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenSnackbarSuccess(false);
	};

	const handleInputChange = (event: any) => {
		if (!event?.target?.value?.length) {
			setFilteredPresetData(presetData);
			return;
		}
		const filteredData = presetData.filter((item: any) => item.dashboard.dashboardName.toLowerCase().includes(event.target.value.toLowerCase()));
		setFilteredPresetData(filteredData);
	};

	const handlePresetOnClick = (preset: any) => {
		onPresetClick(preset);
	};

	const parseDashboardData = (data: any): any => {
		const finalData: any[] = [];
		for (const item of data) {
			const parsedItem = { ...item };
			try {
				parsedItem.dashboard = JSON.parse(parsedItem.dashboard);
			} catch {
				parsedItem.dashboard = {};
			}
			finalData.push(parsedItem);
		}

		finalData.sort((a: any, b: any) => {
			if(parseInt(a?.userId) === -2 && parseInt(b?.userId) !== -2){
				return -1;
			}else if(parseInt(a?.userId) !== -2 && parseInt(b?.userId) === -2){
				return 1;
			}
			else{
				if(a?.dashboard?.favourite && !b?.dashboard?.favourite){
					return -1;
				}else if(b?.dashboard?.favourite && !a?.dashboard?.favourite){
					return 1;
				}
				return 0;
			}
		})
		// finalData.sort((a: any, b: any) => {
		// 	if (a?.dashboard?.favourite) {
		// 		return -1;
		// 	}
		// 	if (b?.dashboard?.favourite) {
		// 		return 1;
		// 	}
		// 	return 0;
		// });
		// finalData.forEach((value, index) => {
		// 	if(value?.userId===-2){
		// 		// console.log("checking.>",value);
		// 		// value.dashboard.favourite =true;
		// 		const fromIndex=index;
		// 		const toIndex=value?.id;
		// 		const element = finalData.splice(fromIndex, 1)[0];
		// 		// console.log("toIndex>",toIndex);
		// 		// console.log("element>",element);
		// 		finalData.splice(toIndex, 0, element);
		// 	}
		// 	// console.log(">>>>>>>>>>>>>>>>>>>",finalData);
		// });
		
		return finalData;
	};
	const getPresetData = async () => {
		setIsLoaderOpen(true);
		const response = await dashboard('list', {});
		if (response?.status === 200) {
			const data = JSON.parse((await response.json()).Data);
			const parsedData = [presetWallboardData, ...parseDashboardData(data)];
			setPresetData(parsedData);
			setFilteredPresetData(parsedData);
			// showNotification('Presets Loaded', { body: 'Presets have been succeslly loaded.' });
		} else {
			setPresetData(PRESET_MOCK);
		}
		setIsLoaderOpen(false);
	};

	const agentDetails = async () => {
		if (Notification.permission === 'default') {
		  const permission = await Notification.requestPermission();
		  console.log('Notification permission not granted.',permission);
		  if (permission !== 'granted') {
			console.log('Notification permission not granted.');
			return;
		  }
		} else if (Notification.permission !== 'granted') {
		  console.log('Notification permission not granted.');
		  return;
		}
	
		const agentData = await getActivityLogDetails();
		console.log('----------->', agentData);
		setActivityData(agentData);
		getAgentAlertMessages(agentData);
	  };
	React.useEffect(() => {
		getPresetData();
		const interval: any = setInterval(() => {
			agentDetails();
		}, WIDGET_REFRESH_INTERVAL.WALLBOARD);
	}, []);

	return (
		<>
			<Grid container direction="row" justifyContent="space-between" alignItems="center">
				<Grid item>
					<h3>Dashboard</h3>
				</Grid>
				<Grid item>
					<TextField className="search-input" onChange={handleInputChange} variant="outlined" />
					<Button variant="contained" onClick={() => navigate('/create')} style={{ marginLeft: '12px' }}>
						Create New
					</Button>
				</Grid>
			</Grid>
			<div>
				<h4>Presets</h4>
				<Grid container direction="row" spacing={4}>
					{filteredPresetData.map((preset: any, idx: number) => (
						<Grid item key={idx}>
							<div className="preset-item" onClick={() => handlePresetOnClick(preset)}>
								<img src={preset?.dashboard?.thumbnail || `${process.env.PUBLIC_URL}/thumbnails/preset.png`} alt="Preset" />
								<Grid container justifyContent="space-between" alignItems="center">
									<Grid item>
										<p className="preset-name">{preset.dashboard?.dashboardName || '-'}</p>
									</Grid>
									<Grid item>
										<IconButton onClick={(event) => toggleFavourite(event, preset)}>
											{preset.dashboard.favourite ? <StarIcon color="warning" /> : <StarOutlineOutlinedIcon />}
										</IconButton>
										<IconButton onClick={(event) => handleClick(event, preset)}>
											<MoreVertOutlinedIcon />
										</IconButton>
									</Grid>
								</Grid>
							</div>
						</Grid>
					))}
				</Grid>
				
				<Menu
					id="basic-menu"
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					MenuListProps={{
						'aria-labelledby': 'basic-button'
					}}
				>
					<MenuItem
						onClick={(event) => {
							handlePresetAction(event, 'EDIT');
						}}
					>
						Edit
					</MenuItem>
					{selectedPreset.dashboard?.dashboardName !== 'Wallboard' && (parseInt(selectedPreset?.userId) !== -2 || localStorage.getItem('userId') === '-2')  && (
						<MenuItem
							onClick={(event) => {
								handlePresetAction(event, 'DELETE');
							}}
						>
							Delete
						</MenuItem>
					)}
				</Menu>
			<Snackbar open={openSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={3000} onClose={handleCloseError}>
				<Alert
					iconMapping={{
						error: <DoDisturbOnIcon style={{ color: 'red' }} />
					}}
					onClose={handleCloseError}
					severity="error"
				>
					{snackbarMessage}
				</Alert>
			</Snackbar>
			<Snackbar sx={{ zIndex: 10005 }} open={openSnackbarSuccess} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={3000} onClose={handleCloseSuccess}>
				<Alert
					iconMapping={{
						error: <DoDisturbOnIcon style={{ color: 'green' }} />
					}}
					onClose={handleCloseSuccess}
					severity="error"
				>
					{snackbarMessageSuccess}
				</Alert>
			</Snackbar>

			</div>
		</>
	);
}

export default Presets;
