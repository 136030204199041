import { useEffect, useRef, useState } from 'react';

const useChartResize = () => {
    const chartElementRef = useRef(null);
    const chartContainerRef = useRef(null);
    const [chartDimensions, setChartDimensions] = useState({ width: 0, height: 0 });
    const reflowChart = () => chartElementRef?.current?.chart?.reflow();
    const redrawChart = () => chartElementRef?.current?.chart?.redraw();

    useEffect(() => {
        const debounce = (func, wait) => {
            let timeout;
            return (...args) => {
                clearTimeout(timeout);
                timeout = setTimeout(() => func.apply(null, args), wait);
            };
        };

        const resizeListener = debounce(() => {
            reflowChart();
            if (chartContainerRef.current) {
                setChartDimensions({
                    width: chartContainerRef.current.offsetWidth * 0.95,
                    height: chartContainerRef.current.offsetHeight * 0.95
                });
            }
        }, 200);

        window.addEventListener('widgetResize', resizeListener);
        window.addEventListener('resize', resizeListener);

        let resizeObserver;
        if (chartContainerRef.current) {
            resizeObserver = new ResizeObserver(debounce(() => {
                resizeListener();
            }, 200));
            resizeObserver.observe(chartContainerRef.current);
        }

        resizeListener();

        return () => {
            window.removeEventListener('widgetResize', resizeListener);
            window.removeEventListener('resize', resizeListener);
            if (resizeObserver && chartContainerRef.current) {
                resizeObserver.unobserve(chartContainerRef.current);
            }
        };
    }, []);

    useEffect(() => {
        reflowChart();
        redrawChart();
    }, [chartDimensions]);

    return { chartElementRef, chartContainerRef, chartDimensions, reflowChart, redrawChart };
};

export default useChartResize;