import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ReactComponent as FilterAltOutlinedIcon } from '../../../static/icons/filter.svg';
import { TabContext, TabList } from '@mui/lab';
import { Box, Card, FormControl, Grid, MenuItem, Select, SelectChangeEvent, Stack, Tab, Theme, Badge, Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as React from 'react';
import { getAgentStateCounter, getWidgetConfig } from '../../../services/api-service';
import { ReactComponent as GraphIcon } from '../../../static/icons/graph.svg';
import { ReactComponent as TableIcon } from '../../../static/icons/table.svg';
import { WIDGET_REFRESH_INTERVAL } from '../../../utils/constants';
import FilterDrawer from '../../FilterDrawer/FilterDrawer';
import OZTable from '../../OZTable/OZTable';
import WidgetDownload from '../../Widget-Download/Widget-Download';
import '../AgentStateCounter/AgentStateCounter.css';
import { getTotalRowAndActualData, checkDataAccessFilters, determineFilterType } from '../../../utils/util';
import useChartResize from '../../../hooks/useChartResize';

const MODE_COLOR_MAP: any = {
	VOICE: '#E75B5C',
	DIGITAL: '#EEBE40',
	MANUAL: '#B0CFA5',
	PREVIEW: '#9CD6E9',
	PROGRESSIVE: '#71B6FB',
	BLENDED: '#BDA7EB'
};

let interval: any;
const initialFilterType = 1;

function AgentStateCounter(props: any) {
	const { useState, useRef } = React;
	const [isDrawerOpen, setIsDrawerOpen] = useState<boolean | undefined>(false);
	// const [selectedPoint, setSelectedPoint] = useState<any>({});
	const [totalAgents, setTotalAgents] = useState<number>(0);
	const [responseLength, setresponseLength] = useState<Number>(0);
	const [graphData, setGraphData] = useState<any>([]);
	const [mode, setMode] = useState<string | null>('all');
	const [rowData, setRowData] = useState<Array<any>>([]);
	const [columnData, setColumnData] = useState<Array<any>>([]);
	const [graphCategories, setGraphCategories] = useState<Array<any>>([]);
	const [showGraphData, setShowGraphData] = useState<Boolean>(true);
	const [allowedFilters, setAllowedFilters] = useState<string[]>(['Campaign', 'Skill', 'Group']);
	// const [filterType, setFilterType] = useState<any>(props?.selectedFilters?.filterSection?.id || initialFilterType);
	const [filterType, setFilterType] = useState<any>(props?.selectedFilters?.filterSection?.id || checkDataAccessFilters() ? 3 : 1);
	const [filterSection, setFilterSection] = useState<any>(props?.selectedFilters?.filterSection || {});
	const [selectedTab, setSelectedTab] = useState<string>('all');
	const downloadContent = useRef<null | any>(null);
	const [agentStateFilter, setAgentStateFilter] = useState(props?.selectedFilters?.filters || []);
	const { chartContainerRef, chartDimensions, chartElementRef, redrawChart } = useChartResize();

	const modeList = [
		{ name: 'All Modes', value: 'all' },
		{ name: 'Voice', value: 'inbound' },
		{ name: 'Digital', value: 'digital' },
		{ name: 'Manual', value: 'manual' },
		{ name: 'Progressive', value: 'progressive' },
		{ name: 'Blended', value: 'blended' },
		{ name: 'Preview', value: 'preview' }
	];

	// const chartElement: any = useRef(null);

	const clearFilter = () => {
		setFilterType(initialFilterType);
		setAgentStateFilter([]);
		props?.handleFilterChange?.([], filterSection, props?.widgetId);
	};

	const handleFilterDelete = (id: Number) => {
		const newFilter = agentStateFilter.filter((item: any) => item.id !== id);
		setAgentStateFilter(newFilter);
		props?.handleFilterChange?.(newFilter, filterSection, props?.widgetId);
	};

	const filterItems = agentStateFilter.map((item: any, index: number) => (
		<Chip key={index} label={item.label} color="primary" variant="outlined" onDelete={() => handleFilterDelete(item.id)} deleteIcon={<CloseOutlinedIcon style={{ fontSize: '12px' }} />} />
	));

	const formatGraphData = (data: any) => {
		// console.log(data)
		const stateModeMap: any = {};
		const stateCount: any = {};
		for (const item of data) {
			if (!stateModeMap[item.AgentMode]) {
				stateModeMap[item.AgentMode] = { [item.AgentState]: 0, visible: item.visible };
			}
			if (!stateCount[item.AgentState]) {
				stateCount[item.AgentState] = 0;
			}
			if (!stateModeMap[item.AgentMode][item.AgentState]) {
				stateModeMap[item.AgentMode][item.AgentState] = 0;
			}
			stateModeMap[item.AgentMode][item.AgentState] += +item.AgentCount || 0;
			stateCount[item.AgentState] += +item.AgentCount || 0;
		}
		const categories = Object.keys(stateCount).map((category) => `${category}(${stateCount[category]})`);
		const graphData = [];

		for (const agentMode of Object.keys(stateModeMap)) {
			const seriesData: any = { name: agentMode, data: [], color: MODE_COLOR_MAP[agentMode], visible: stateModeMap[agentMode].visible };
			for (const agentState of Object.keys(stateCount)) {
				if (stateModeMap[agentMode][agentState]) {
					seriesData.data.push(stateModeMap[agentMode][agentState]);
				} else {
					seriesData.data.push(null);
				}
			}
			graphData.push(seriesData);
		}
		// console.log(categories)
		return { graphData, categories };
	};

	const formatTableData = (data: any) => {
		const tableDataMap: any = {};
		const agentStatesCount: any = {};
		for (const item of data) {
			if (selectedTab !== 'all' && !item.AgentMode.toLowerCase().includes(selectedTab?.toLowerCase())) {
				continue;
			}
			if (!tableDataMap[item.AgentMode]) {
				tableDataMap[item.AgentMode] = { mode: item.AgentMode, skills: '', agents: 0, ACW: 0, READY: 0, BUSY: 0 };
			}
			if (!agentStatesCount[item.AgentState]) {
				agentStatesCount[item.AgentState] = 0;
			}
			if (!tableDataMap[item.AgentMode][item.AgentState]) {
				tableDataMap[item.AgentMode][item.AgentState] = 0;
			}
			agentStatesCount[item.AgentState] += +item.AgentCount;
			tableDataMap[item.AgentMode][item.AgentState] += +item.AgentCount;
			tableDataMap[item.AgentMode].agents += +item.AgentCount;
		}
		const tableData = [];
		for (const key in tableDataMap) {
			tableData.push(tableDataMap[key]);
		}
		return { tableData, agentStatesCount };
	};
	const updateSeriesFilter = (agentStateData: any[] | undefined): any[] | undefined => {
		if (mode === 'all') {
			return agentStateData?.map((dataItem) => {
				dataItem.visible = true;
				return dataItem;
			});
		}
		return agentStateData?.map((dataItem) => {
			dataItem.visible = dataItem.AgentMode.toLowerCase().includes(mode?.toLowerCase());
			return dataItem;
		});
	};

	const getTotalAndActualData = (data: any) => {
		const { totalRow, actualData } = getTotalRowAndActualData(data, 'AgentMode', true);
		const totalAgentsSum = totalRow.reduce((sum: number, item: any) => sum + parseInt(item.AgentCount), 0);
		return { totalAgentsSum, actualData };
	};

	const retriveGraphData = async (filters = '0') => {
		const data = await getAgentStateCounter({ filters, type: filterType });
		const { totalAgentsSum, actualData } = getTotalAndActualData(data);
		const filteredGraphData = updateSeriesFilter(actualData);
		const { categories: updatedCategorries, graphData: formattedGraphData } = formatGraphData(filteredGraphData);
		// console.log("categories::::", updatedCategorries)
		// console.log("formattedGraphData", formattedGraphData)
		const updatedFormattedGraphData = formattedGraphData.map(item => {
			if (item.name === "INBOUND") {
				return { ...item, name: "VOICE" };
			}
			return item;
		});
		setGraphData(updatedFormattedGraphData);

		setGraphCategories(updatedCategorries);

		setTotalAgents(totalAgentsSum);
		// chartElement?.current?.chart?.redraw();
		redrawChart();
	};

	const retrieveTableData = async (filters = '0') => {
		const data = await getAgentStateCounter({ filters, type: filterType });
		const { totalAgentsSum, actualData } = getTotalAndActualData(data);
		const filteredTableData = updateSeriesFilter(actualData);
		const { tableData: formattedTableData, agentStatesCount } = formatTableData(filteredTableData);
		const updatedFormattedGraphData = formattedTableData.map(item => {
			if (item.mode === "INBOUND") {
				return { ...item, mode: "VOICE" };
			}
			return item;
		});
		const columns = [
			{ name: 'Modes', key: 'mode' },
			{ name: 'Agents', key: 'agents', subTitle: totalAgentsSum },
			{ name: 'Ready', key: 'READY', subTitle: agentStatesCount.READY },
			{ name: 'Busy', key: 'BUSY', subTitle: agentStatesCount.BUSY },
			{ name: 'ACW', key: 'ACW', subTitle: agentStatesCount.ACW },
			{ name: 'Pause', key: 'PAUSED', subTitle: agentStatesCount.PAUSED },
			{ name: 'Calling', key: 'Calling', subTitle: agentStatesCount.Calling }
		];
		// setRowData(formattedTableData);
		setRowData(updatedFormattedGraphData);
		setTotalAgents(totalAgentsSum);
		setColumnData(columns);
	};

	const handleViewToggle = () => {
		if (showGraphData) {
			if (!rowData?.length) {
				retrieveTableData();
			}
			setShowGraphData(false);
		} else {
			setShowGraphData(true);
		}
	};

	const getWidgetData = (filterIds: any) => {
		if (!showGraphData) {
			retrieveTableData(filterIds);
		} else {
			retriveGraphData(filterIds);
		}
	};

	const handlePointClick = (point: any) => {
		// console.log('Clicked point', point);
	};
	const handleModeChange = (event: SelectChangeEvent<string | null>) => {
		setMode(event?.target?.value);
	};

	const handleOnTabChange = (event: React.SyntheticEvent<Element, Event>, value: any) => {
		setSelectedTab(value);
	};

	const toggleDrawer = (isOpen: boolean) => {
		setIsDrawerOpen(isOpen);
	};

	const handleDownload = (type: string) => {
		// console.log(type);
	};

	const options = {
		chart: {
			type: 'column',
			height: chartDimensions.height + 36,
		},
		credits: { enabled: false },
		title: { text: '' },
		xAxis: {
			title: {
				text: '',
				style: { fontWeight: 'bold' }
			},
			categories: graphCategories,
			crosshair: true
		},
		yAxis: {
			title: {
				text: 'Total Numbers',
				style: { fontWeight: 'bold' }
			},
			gridLineDashStyle: 'dash'
		},
		legend: {
			layout: 'horizontal',
			align: 'left',
			verticalAlign: 'top',
			itemMarginTop: 10
		},
		tooltip: {
			formatter(this: any): any {
				return `${this.y}`;
			}
		},
		plotOptions: {
			series: {
				cursor: 'pointer',
				borderRadiusTopLeft: '50%',
				borderRadiusTopRight: '50%',
				pointWidth: 15,
				borderRadius: 7,
				dataLabels: {
					enabled: true
				},
				point: {
					events: {
						click: function ({ point }: any) {
							handlePointClick(point);
						}
					}
				}
			}
		},
		series: graphData
	};

	const handleFilterExistingData = (filteredData: any) => {
		setAgentStateFilter(filteredData);
		props?.handleFilterChange?.(filteredData, filterSection, props?.widgetId);
	};

	const handleApplyFilters = ({ selectedFilters, filterType: newFilterType, filterSection: newfilterSection }: any) => {
		setAgentStateFilter(selectedFilters);
		setFilterType(newFilterType);
		setFilterSection(newfilterSection);
		props?.handleFilterChange?.(selectedFilters, newfilterSection, props?.widgetId);
		toggleDrawer(false);
	};

	// React.useEffect(() => {
	// 	const resizeListener = () => {
	// 		chartElement?.current?.chart?.reflow();
	// 	};
	// 	window.addEventListener('widgetResize', resizeListener);
	// 	window.addEventListener('resize', resizeListener);

	// 	return () => {
	// 		clearInterval(interval);
	// 		window.removeEventListener('widgetResize', resizeListener);
	// 	};
	// }, []);

	React.useEffect(() => {
		const fetchWidgetConfig = async () => {
			try {
				if (checkDataAccessFilters()) {
					let widgetConfig = await getWidgetConfig('102', 'widget');
					if (widgetConfig) {
						if (typeof widgetConfig === 'string') {
							widgetConfig = JSON.parse(widgetConfig);
						}
						// Check the condition and set the appropriate filters
						const filters = widgetConfig.data && checkDataAccessFilters()
							? widgetConfig.data
							: widgetConfig.config;

						if (filters) {
							const filtersArray = filters.split(',');
							setAllowedFilters(filtersArray);
							const determinedFilterType = determineFilterType(filtersArray);
							if (determinedFilterType !== undefined) {
								setFilterType(determinedFilterType);
							}
						}
					}
				}
			} catch (error) {
				console.error('Error fetching widget config:', error);
			}
		};
		fetchWidgetConfig();
	}, []);

	React.useEffect(() => {
		const filterIds = (agentStateFilter.length && !(responseLength === agentStateFilter.length)) ? agentStateFilter.map((item: any) => item.id).join(',') : '0';
		// const filterIds = agentStateFilter.length ? agentStateFilter.map((item: any) => item.id).join(',') : '0';
		getWidgetData(filterIds);
		if (interval) {
			clearInterval(interval);
		}
		interval = setInterval(() => getWidgetData(filterIds), WIDGET_REFRESH_INTERVAL.AGENT_STATE_COUNTER);
	}, [mode, agentStateFilter, selectedTab, showGraphData]);

	function getRowData(row: any) {
		return row;
	}

	return (
		<>
			<Card className="customCard">
				<div className="card-header">
					<div className="card-title">
						Agent State Counter
						<span className="card-title-badge">Live</span>
						<span className="sub-agentStateCounter-title">{`Total Agents: `}<span style={{ color: '#212121' }}>{totalAgents}</span></span>
					</div>
					<div className="card-actions">
						{showGraphData && (
							<FormControl sx={{ minWidth: 120, p: 0 }}>
								<Select
									placeholder="Select mode"
									id="mode-select"
									value={mode}
									size="small"
									displayEmpty
									inputProps={{ 'aria-label': 'Without label' }}
									onChange={handleModeChange}
									sx={{ p: 0 }}
								>
									{modeList.map((mode, index) => (
										<MenuItem key={index} value={mode.value}>
											{mode.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						)}
						<div className="card-icon" onClick={() => handleViewToggle()}>
							{showGraphData ? <TableIcon /> : <GraphIcon />}
						</div>
						<WidgetDownload xlsx={showGraphData ? false : true} rowData={getRowData(rowData)} columns={columnData} element={showGraphData ? chartContainerRef : downloadContent} handleDownload={handleDownload} name={'Agent State Counter'}></WidgetDownload>
						<div className="card-icon" onClick={() => toggleDrawer(true)}>
							<Badge badgeContent={agentStateFilter.length} color="primary">
								<FilterAltOutlinedIcon fontSize="small" style={{ color: '#536580' }} />
							</Badge>
						</div>
					</div>
				</div>
				{agentStateFilter.length > 0 && (<Box sx={{ pt: 2, pl: 2 }}>
					<div className="filter-outer">
						<div className="filter-container">
							<Stack direction="row" spacing={1}>
								{filterItems}
							</Stack>
						</div>
						<div className="filter-remove" onClick={clearFilter}>
							Remove
						</div>
					</div>
				</Box>)}
				{!showGraphData &&
					<Box sx={{ width: '100%', typography: 'body1' }}>
						<TabContext value={selectedTab}>
							<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
								<TabList onChange={handleOnTabChange} aria-label="lab API tabs example" className="customTabs">
									<Tab label="All" value="all" />
									<Tab label="Voice" value="inbound" />
									<Tab label="Digital" value="digital" />
									<Tab label="Manual" value="manual" />
									<Tab label="Preview" value="preview" />
									<Tab label="Progressive" value="progressive" />
									<Tab label="Blended" value="blended" />
								</TabList>
							</Box>
						</TabContext>
					</Box>
				}
				<Box sx={{ height: agentStateFilter.length > 0 ? chartDimensions.height < 360 ? 'calc(100% - 116px)' : 'calc(100% - 104px)' : chartDimensions.height < 360 ? 'calc(100% - 64px)' : 'calc(100% - 54px)' }} ref={chartContainerRef}>
					{showGraphData ? (
						<HighchartsReact ref={chartElementRef} highcharts={Highcharts} options={options} />
					) : <OZTable downloadContent={downloadContent} columns={columnData} rows={rowData} noMaxHeight={true} height={chartDimensions.height} />
					}
				</Box>
			</Card >
			<FilterDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} applyFilters={handleApplyFilters} allowedFilters={allowedFilters} existingSelectedFilters={agentStateFilter} setResponseLength={setresponseLength} existingFilterCategory={filterType} applyFilterOnExistingData={handleFilterExistingData} />
			{/* <FilterDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} applyFilters={handleApplyFilters} existingSelectedFilters={agentStateFilter} setResponseLength={setresponseLength} existingFilterCategory={filterType} applyFilterOnExistingData={handleFilterExistingData}/> */}
		</>
	);
}
export default AgentStateCounter;
