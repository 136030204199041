import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ReactComponent as FilterAltOutlinedIcon } from '../../../static/icons/filter.svg';
import Card from '@mui/material/Card';
import * as React from 'react';
import { getInteractionQueueCounter, getWidgetConfig } from '../../../services/api-service';
import FilterDrawer from '../../FilterDrawer/FilterDrawer';
import OZTable from '../../OZTable/OZTable';
import WidgetDownload from '../../Widget-Download/Widget-Download';
// import WidgetGraph from '../../Widget-Graph/Widget-Graph';

import { Badge, Box, Chip, Stack, Grid, ToggleButtonGroup, ToggleButton, Theme } from '@mui/material';
import { WIDGET_REFRESH_INTERVAL } from '../../../utils/constants';
import '../QueueCounters/QueueCounters.css';
import { makeStyles } from '@mui/styles';
import useChartResize from '../../../hooks/useChartResize';
import { checkDataAccessFilters, determineFilterType } from '../../../utils/util';
// import ThresholdDrawer from '../../ThresholdDrawer/ThresholdDrawer';


const useStyles: any = makeStyles((theme: Theme) => ({
	boundButton: {
		padding: '5px !important',
		minWidth: 70
	},
	boundButtonMain: {
		padding: '5px !important',
		width: 'inherit !importnt',
	}
}));
let interval: any;

// const thresholdFields = [
// 	{ label: 'Longest wait time', value: '', key: 'LongestWaitTime', condition: 'GREATER_THAN' },
// 	{ label: 'Calls Waiting', value: '', key: 'QueueCount', condition: 'GREATER_THAN' }
// ];

export default function InteractionQueueCounter(props: any) {
	const classes = useStyles();
	const { useState, useRef } = React;
	const [isDrawerOpen, setIsDrawerOpen] = useState<boolean | undefined>(false);
	const [filterType, setFilterType] = useState<any>(props?.selectedFilters?.filterSection?.id || checkDataAccessFilters() ? 2 : '1');
	const [filterSection, setFilterSection] = useState<any>(props?.selectedFilters?.filterSection || {});
	const [responseLength, setresponseLength] = useState<Number>(0);
	const [rowData, setRowData] = useState<Array<any>>([]);
	const [columnData, setColumnData] = useState<Array<any>>([]);
	const [thresholdItems, setThresholdItems] = useState<Array<any>>([]);
	const [interactionQueueFilter, setInteractionQueueFilter] = useState<Array<any>>(props?.selectedFilters?.filters || []);
	// const [isThresholdDrawerOpen, setIsThresholdDrawerOpen] = useState<boolean | undefined>(false);
	const downloadContent = useRef<null | any>(null);
	const [bound, setBound] = useState<any>('calls');
	const [selectWise, setSelectWise] = useState<any>('SkillWise');
	const { chartContainerRef, chartDimensions, } = useChartResize();
	const [allowedFilters, setAllowedFilters] = useState<string[]>(['Campaign', 'Skill']);

	// const additionalApiParams = {campaignType: 'chat'};

	const retrieveTableData = async (filters = '0') => {
		const columnsSkillWise = [
			{ name: 'Skill', key: 'SkillName' },
			{ name: 'Campaign', key: 'CampaignName' },
			{ name: 'Caller Number', key: 'CallerNo' },
			{ name: 'Duration', key: 'Duration', type: 'MIN_SEC' }
		];
		const columnsAgentWise = [
			{ name: 'Agent', key: 'AgentName' },
			{ name: 'Skill', key: 'SkillName' },
			{ name: 'Campaign', key: 'CampaignName' },
			{ name: 'Caller Number', key: 'CallerNo' },
			{ name: 'Phone', key: 'PhoneName' },
			{ name: 'Duration', key: 'Duration' },
		];

		const columnsSkillWiseChats = [
			{ name: 'Skill', key: 'SkillName' },
			{ name: 'Campaign', key: 'CampaignName' },
			{ name: 'Caller Number', key: 'CallerNo' },
			{ name: 'Duration', key: 'Duration', type: 'MIN_SEC' }
		];
		const columnsAgentWiseChats = [
			{ name: 'Agent', key: 'AgentName' },
			{ name: 'Skill', key: 'SkillName' },
			{ name: 'Campaign', key: 'CampaignName' },
			{ name: 'Caller Number', key: 'CallerNo' },
			{ name: 'Phone', key: 'PhoneName' },
			{ name: 'Duration', key: 'Duration', type: 'MIN_SEC' },
		];

		let rows: any = [];

		if (bound === 'calls') {
			rows = await getInteractionQueueCounter({ filters, type: filterType, CallQueueType: selectWise, CallType: bound });
		} else if (bound === 'digital') {
			rows = await getInteractionQueueCounter({ filters, type: filterType, CallQueueType: selectWise, CallType: bound });
			// rows = await getInteractionQueueCounterChats({ filters})
			// if(selectWise === 'SkillWise'){
			//     // rows = dummyAgentWise;
			// 	// rows = await getInteractionQueueCounterChats({ filters})
			// }else if(selectWise === 'AgentWise'){
			//     // rows = dummyAgentWise;
			// 	// rows = await getInteractionQueueCounterChats({ filters});
			// 	rows = rows?.filter((item: any) => item?.agentId);
			// }
		}
		setRowData(rows);
		if (bound === 'calls') {
			if (selectWise === 'SkillWise') {
				setColumnData(columnsSkillWise);
			} else if (selectWise === 'AgentWise') {
				setColumnData(columnsAgentWise);
			}
		} else if (bound === 'digital') {
			if (selectWise === 'SkillWise') {
				setColumnData(columnsSkillWiseChats);
			} else if (selectWise === 'AgentWise') {
				setColumnData(columnsAgentWiseChats);
			}
		}
		// setColumnData(columns);
	};

	const handleDownload = (type: string) => {
		console.log(type);
	};

	const toggleDrawer = (isOpen: boolean) => {
		setIsDrawerOpen(isOpen);
	};

	const handleFilterDelete = (id: Number) => {
		const newFilter = interactionQueueFilter.filter((item: any) => item.id !== id);
		setInteractionQueueFilter(newFilter);
		props?.handleFilterChange?.(newFilter, filterSection, props?.widgetId);
	};

	const clearFilter = () => {
		console.info('Clear all filter.');
		setInteractionQueueFilter([]);
		props?.handleFilterChange?.([], filterSection, props?.widgetId);
	};

	const handleFilterExistingData = (filteredData: any) => {
		setInteractionQueueFilter(filteredData);
		props?.handleFilterChange?.(filteredData, filterSection, props?.widgetId);
	};

	const handleApplyFilters = ({ selectedFilters, filterType: newFilterType, filterSection: newfilterSection }: any) => {
		setInteractionQueueFilter(selectedFilters);
		setFilterType(newFilterType);
		setFilterSection(newfilterSection);
		props?.handleFilterChange?.(selectedFilters, newfilterSection, props?.widgetId);
		toggleDrawer(false);
	};

	const handleBound = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
		if (newAlignment === null || newAlignment === undefined) return;
		setBound(newAlignment);
		setInteractionQueueFilter([]);
		props?.handleFilterChange?.([], filterSection, props?.widgetId);
		setFilterType('1');
	};

	const handleSelectWise = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
		if (newAlignment === null || newAlignment === undefined) return;
		setSelectWise(newAlignment);
	};

	const filterItems = interactionQueueFilter.map((item: any, index: number) => (
		<Chip key={index} label={item.label} color="primary" variant="outlined" onDelete={() => handleFilterDelete(item.id)} deleteIcon={<CloseOutlinedIcon style={{ fontSize: '12px' }} />} />
	));

	// const toggleThresholdDrawer = (isOpen: boolean, data: any = null) => {
	// 	setIsThresholdDrawerOpen(isOpen);
	// 	if (data) {
	// 		setThresholdItems(data);
	// 	}
	// };

	// const updateThresholdItems = async () => {
	// 	let newThresholdItems = [];
	// 	const response: any = await getFilters('Skill');
	// 	newThresholdItems.push({ label: 'Organization Level', fields: thresholdFields, isOrgSkill: true });
	// 	for (const row of response) {
	// 		if (row.name !== 'All') {
	// 			newThresholdItems.push({ label: row.Name, key: 'SkillName', value: row.Name, fields: thresholdFields });
	// 		}
	// 	}
	// 	setThresholdItems(newThresholdItems);
	// };

	React.useEffect(()	=> {
		const fetchWidgetConfig = async () => {
			try {
				if(checkDataAccessFilters()) {
					let widgetConfig = await getWidgetConfig('151', 'widget');
					if (widgetConfig) {
						if (typeof widgetConfig === 'string') {
							widgetConfig = JSON.parse(widgetConfig);
						}
						// Check the condition and set the appropriate filters
						const filters = widgetConfig.data && checkDataAccessFilters()
										? widgetConfig.data
										: widgetConfig.config;
		
						if (filters) {
							const filtersArray = filters.split(',');
							setAllowedFilters(filtersArray);
							const determinedFilterType = determineFilterType(filtersArray);
							if (determinedFilterType !== undefined) {
								setFilterType(determinedFilterType);
							}
						}
					}
				}
			} catch (error) {
				console.error('Error fetching widget config:', error);
			}
		};
		fetchWidgetConfig();
	}, []);

	React.useEffect(() => {
		// updateThresholdItems();
		return () => {
			clearInterval(interval);
		};
	}, []);

	React.useEffect(() => {
		// const filterIds = (agentListFilter.length && !(responseLength === agentListFilter.length)) ? agentListFilter.map((item: any) => item.id).join(',') : '0';
		const filterIds = (interactionQueueFilter.length && !(responseLength === interactionQueueFilter.length)) ? interactionQueueFilter.map((item: any) => item.id).join(',') : '0';
		retrieveTableData(filterIds);
		if (interval) {
			clearInterval(interval);
		}
		interval = setInterval(() => retrieveTableData(filterIds), WIDGET_REFRESH_INTERVAL.INTERACTION_QUEUE);
	}, [interactionQueueFilter, thresholdItems, selectWise, bound]);


	function getRowData(row: any) {
		// row = row?.map((item: any) => {
		// 	let rowObj = {
		// 		...item,
		// 		LongestWaitTime: secondsToMinSec(item?.LongestWaitTime),
		// 	};
		// 	return rowObj;
		// });
		return row;
	}

	return (
		<Card ref={downloadContent} className="customCard">
			<div className="card-header">
				<div className="card-title">
					Interaction Queues <div className="card-title-badge">Live</div>
				</div>
				<div className="card-actions">
					<ToggleButtonGroup value={bound} color="primary" exclusive onChange={handleBound} aria-label="bound">
						<ToggleButton value="calls" className={classes.boundButton} aria-label="calls">
							<span>Calls</span>
						</ToggleButton>
						<ToggleButton value="digital" className={classes.boundButton} aria-label="digital">
							<span>Digital</span>
						</ToggleButton>
					</ToggleButtonGroup>
					<WidgetDownload element={downloadContent} handleDownload={handleDownload} xlsx rowData={getRowData(rowData)} columns={columnData} name={'Interaction Queues'}></WidgetDownload>
					<div className="card-icon" onClick={() => toggleDrawer(true)}>
						<Badge badgeContent={interactionQueueFilter.length} color="primary">
							<FilterAltOutlinedIcon fontSize="small" style={{ color: '#536580' }} />
						</Badge>
					</div>
					{/* <div onClick={() => toggleThresholdDrawer(true)}>
						<WidgetGraph></WidgetGraph>
					</div> */}
				</div>
			</div>
			{bound === 'calls' ?
				<>
					{/* <FilterDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} applyFilters={handleApplyFilters} allowedFilters={['Campaign', 'Skill']} existingSelectedFilters={interactionQueueFilter} setResponseLength={setresponseLength} existingFilterCategory={filterType} applyFilterOnExistingData={handleFilterExistingData} /> */}
					<FilterDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} applyFilters={handleApplyFilters} allowedFilters={allowedFilters} existingSelectedFilters={interactionQueueFilter} setResponseLength={setresponseLength} existingFilterCategory={filterType} applyFilterOnExistingData={handleFilterExistingData} />
				</>
				:
				<>
					<FilterDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} applyFilters={handleApplyFilters} allowedFilters={allowedFilters} existingSelectedFilters={interactionQueueFilter} setResponseLength={setresponseLength} existingFilterCategory={filterType} applyFilterOnExistingData={handleFilterExistingData} />
					{/* <FilterDrawer isOpen={isDrawerOpen} RunningStatusWidget={true} allowedFilters={['Campaign']} toggleDrawer={toggleDrawer} applyFilters={handleApplyFilters} additionalApiParams={additionalApiParams} existingSelectedFilters={interactionQueueFilter} setResponseLength={setresponseLength} existingFilterCategory={filterType} applyFilterOnExistingData={handleFilterExistingData}/> */}
				</>
			}
			{/* <FilterDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} applyFilters={handleApplyFilters} allowedFilters={['Campaign', 'Skill']} existingSelectedFilters={interactionQueueFilter} setResponseLength={setresponseLength} /> */}
			<Box sx={{ width: '100%', typography: 'body1', height: '100%', }}>
				{interactionQueueFilter.length > 0 && (
					<Box className='customTabPanel'>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<div className="filter-outer">
									<div className="filter-container">
										<Stack direction="row" spacing={1}>
											{filterItems}
										</Stack>
									</div>
									<div className="filter-remove" onClick={clearFilter}>
										Remove
									</div>
								</div>
							</Grid>
						</Grid>
					</Box>
				)}

				<ToggleButtonGroup fullWidth={true} value={selectWise} color="primary" exclusive onChange={handleSelectWise} aria-label="selectwise">
					<ToggleButton value="SkillWise" className={classes.boundButtonMain} aria-label="SkillWise">
						<span>Skill Wise Queue</span>
					</ToggleButton>
					<ToggleButton value="AgentWise" className={classes.boundButtonMain} aria-label="AgentWise">
						<span>Agent Wise Queue</span>
					</ToggleButton>
				</ToggleButtonGroup>
				<Box sx={{ width: '100%', height: interactionQueueFilter.length > 0 ? 'calc(100% - 136px)' : 'calc(100% - 64px)', }} ref={chartContainerRef} >
					<OZTable
						columns={columnData}
						rows={rowData}
						noMaxHeight={true}
						height={Math.floor(chartDimensions.height)}
						downloadContent={downloadContent}
					/>
				</Box>
			</Box>
			{/* <ThresholdDrawer isOpen={isThresholdDrawerOpen} title={'Queue Counter'} toggleDrawer={toggleThresholdDrawer} thresholdItems={thresholdItems} /> */}
		</Card>
	);
}
