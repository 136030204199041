import React, { useState, useMemo, useEffect } from 'react';
import { Formik } from 'formik';
import { Button, Grid, IconButton, Stack, TextField, Tooltip, Typography, Divider } from '@mui/material';
import { createBlockNumber, getBlockNumberGroupDropdown, updateExpiryDateBlockNumber } from '../../../services/api-service';
import OZDialog from "../../../components/admin/common/OZDialog";
import { showErrorNotification, showSuccessNotification } from '../../../components/admin/common/NotiService';
import LoadingScreen from '../../../components/admin/common/OZLoadingScreen/OZLoadingScreen';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { connect } from "formik";
import moment from 'moment';
import OZInput from '../../../components/admin/common/OZInput';
import OZCheckbox from '../../../components/OZCheckbox/OZCheckbox';
import CustomAutoComplete from '../../../components/admin/common/AutoComplete/CustomAutoComplete';
import OZFCheckbox from '../../../components/admin/common/OZFCheckbox';


const EditExpiryDateBlockGroupForm = (props) => {

    const [openEditBlockGroupform, setEditBlockGroupform] = useState(true);
    const [disabledByLoading, setDisabledByLoading] = useState(false);
    const [newLoading, setNewLoading] = useState(false);
    const [blockgroup, setBlockGroup] = useState([]);
    const [loading, setLoading] = useState(false);

    const closeEditBlockgroupform = () => {
        setEditBlockGroupform(false);
        props.onClose();
    }
    const closeUpdateEditBlockgroupform = () => {
        setEditBlockGroupform(false);
        props.onClose();
        props.onCloseUpdate();
    }

    useEffect(() => {
        getBlocknumberGroupOptions();
    }, []);

    const onUpadteValues = (data) => {
        console.log("here you will get data ", data);

        const { id, ...newData } = data;

        const ids = newData.blockgroups.map(group => ({ id: group.id }));
        newData.blockgroups=ids
        console.log("newData", newData);

        const isUpdate=true
        const dataToApi = {body: newData, isUpdate: isUpdate}
        
        console.log("dataToApi", dataToApi);
        createBlockNumber(dataToApi)
          .then((resp) => {
            if (resp?.Status === "Success") {
                closeUpdateEditBlockgroupform();
                showSuccessNotification(`Block Number: ${data.blockedNumber} updated Successfully`);
            }
            else {
              // console.log(resp.Message)
              // showErrorNotification(JSON.stringify(resp.Message));
              showErrorNotification(resp?.Message?.name || resp?.Message?.description || resp?.Message);
            }
          })
          .catch((e) => {
            console.log(e)
            showErrorNotification(JSON.stringify(e));
          });
        //api call to update block groups
        // props.update(data.id);
    }


    async function getBlocknumberGroupOptions() {
        try {
            getBlockNumberGroupDropdown().then(resp => {
                setBlockGroup(resp.Data);
            }).catch(e => {
                showErrorNotification('No BlockNumber Group Data, Check your Internet Connection');
                setBlockGroup([]);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    }

    // async function addUploadFile(data, id, name) {
    //     delete data.expirypropsGroupdate;
    //     setNewLoading(true);
    //     try {
    //         updateExpiryDateBlockNumber(data).then(resp => {
    //             if (resp.Status === "Success") {
    //                 // showSuccessNotification(`${name} ${resp.Message}`);
    //                 showSuccessNotification(`${resp.Message}`);
    //                 props.update(id);
    //                 closeEditBlockgroupform();
    //                 setNewLoading(false);
    //             } else {
    //                 setNewLoading(false);
    //                 showErrorNotification(JSON.stringify(resp.Message));
    //             }
    //         }).catch(e => {
    //             setNewLoading(false);
    //             showErrorNotification(JSON.stringify(e));
    //         })
    //     }
    //     catch (e) {
    //         setNewLoading(false);
    //         showErrorNotification(JSON.stringify(e));
    //     }
    // }

    const formButtonStyle = useMemo(
        () => ({
            borderRadius: '8px',
            fontWeight: '400',
            fontSize: '14px',
        }),
        []
    );

    let styleFieldHeader = useMemo(
        () => ({
            fontSize: "0.75rem",
            height: '0.875rem',
            color: '#99A0A8',
            lineHeight: "0.875rem",
        }),
        []
    );

    let styleFieldValue = useMemo(
        () => ({
            height: '1rem',
            fontWeight: 400,
            fontSize: "0.875rem",
            color: '#212121',
            lineHeight: "1rem",
        }),
        []
    );

    let basicStyle = useMemo(
        () => ({
            fontSize: '0.75rem', fontWeight: 400, color: "#99A0A8"
        }),
        []
    );

    const DataForm = (props) => {
        console.log("props", props)

        let initData = props.data
        
        return (
            <div >
                <Formik
                    initialValues={initData}
                    onSubmit={(values) => {
                        console.log("values in submit",values)
                        let saveData = structuredClone(values);
                        let flag = 0;
                        if (saveData.expiryDate === 'Invalid Date') {
                            flag = 1;
                            showErrorNotification("Invalid Expiry date, Please check the date passed");
                            return;
                        }
                        if (saveData.blockgroups?.length<1) {
                            showErrorNotification("Atleast one group is required");
                            return;
                        } else if (saveData.expiryDate !== null || saveData.expiryDate !== "" || saveData.expiryDate !== 'Invalid Date') {
                            //new doe for testing purpose:::::::::::://
                            let CDate = new Date().toString();
                            let EEPirydate = moment(saveData.expiryDate, 'DD-MM-YYYY HH:mm:ss').toDate();
                            const timestampCDate = new Date(CDate).getTime();
                            const timestampEEPiryDate = new Date(EEPirydate).getTime();
                            //new doe for testing purpose:::::::::::://
                            if (timestampCDate > timestampEEPiryDate) {      
                                if (moment(saveData.expiryDate, 'YYYY-MM-DD HH:mm:ss', true).isValid()) {                                    
                                    if (((new Date(saveData.expiryDate).getTime()) < timestampCDate)){
                                        showErrorNotification("Expiry date cannot be less than the Current Date Time");
                                        return;
                                    }
                                }
                                else{
                                    flag = 1;
                                    showErrorNotification("Expiry date cannot be less than the Current Date Time");
                                    return;
                                }                                                          
                            } else {
                                let newExpiryDate = moment(saveData?.expiryDate, 'DD-MM-YYYY HH:mm:ss');
                                saveData.expiryDate = newExpiryDate.format("YYYY-MM-DD HH:mm:ss");
                            }
                        }
                        if (flag === 0) {
                            console.log("here you will get full details after validation", saveData)
                            onUpadteValues(saveData);
                            // addUploadFile(saveData, props?.data?.expirypropsGroupdate?.id, saveData?.expirypropsGroupdate?.name);
                        }
                    }}
                >
                    {(props) => (
                        <form
                            onSubmit={props.handleSubmit} 
                            id="blockNumberEdit-Form" className='ozcw-scrollbar' style={{
                                maxHeight: '70vh',
                                padding: '1px'
                            }}>
                            <div style={{ width: '22rem', minHeight: '18rem' }}>
                                <Grid container spacing={2} direction='column'>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Typography sx={basicStyle}>Expiry Date</Typography>
                                        {/* <MaterialUIPickers name="expiryDate" values={props?.data} /> */}
                                        <MaterialUIPickers name="expiryDate" values={props?.values} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography sx={basicStyle}>Groups*</Typography>
                                        {/* {(checked.length > 1) && (blockedNumbersLength > 0) && <DeleteDialog
                                        title="Delete Group"
                                        content="This will delete this group permanently. You cannot undo this action."
                                        imageStyle={{ marginLeft: '7.5rem', marginBottom: "1px" }}
                                        deleteFunction={() => { checklistProps.groupData.blockgroups = newBlockNumbers; checklistProps.deleteBlockNumer(checklistProps.groupData) }} /> */}
                                        {/* } */}
                                        <div style={{ minHeight: '22.0625rem', marginTop: 5, border: '1px solid #D6D6D7', borderRadius: '8px 8px 8px 8px', backgroundColor: '#FFFFFF' }}>
                                            <div style={{ paddingTop: '10px', paddingLeft: '15px', fontSize: '14px', fontWeight: '400', color: '#212121' }}>
                                                {props?.values?.blockgroups?.length + ' Selected...'}
                                            </div>
                                            <span style={{ flexGrow: 1, alignSelf: "flex-end" }}>
                                                <Divider sx={{ borderColor: "#D6D6D7" }} />
                                            </span>
                                            <div style={{ marginTop: '20px', marginLeft: '17px', minHeight: '2.75rem', paddingRight: '17px' }}>
                                                <CustomAutoComplete
                                                    mode='Add'
                                                    limitTags={2}
                                                    name="blockgroups"
                                                    placeholder={"Search Group Name"}
                                                    Datas={blockgroup}
                                                    optionLabelName='name'
                                                    showCheckBox={true}
                                                    multiple={true}
                                                    showselectall="true"
                                                    popupIcon={null}
                                                    showAvatar={true}
                                                />
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} direction={'row'} flexGrow={1} flex sx={{ marginTop: 7, paddingBottom: '10px' }} justifyContent='end'>
                                    <Grid item>
                                        <Button style={formButtonStyle} onClick={closeEditBlockgroupform} variant="outlined" type="submit">
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item >
                                        <Button style={formButtonStyle} variant="contained" type="submit" form="blockNumberEdit-Form">
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>

                                {disabledByLoading && (
                                    <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }}>
                                        <LoadingScreen />
                                    </div>
                                )
                                }
                                {newLoading &&
                                    <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }}>
                                        <LoadingScreen />
                                    </div>
                                }
                            </div>
                        </form>
                    )}
                </Formik>
            </div >
        );
    }
    return (
        <div>
            <OZDialog
                hideActions
                title={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title={props.props.blockedNumber}><div
                            className="oz-managenumbers-drawer-title-heading"
                            sx={{
                            fontWeight: 600,
                            fontSize: "1.125rem",
                            width: "11rem",
                            marginLeft: "11px",
                            marginTop: '1px'
                            }}
                            flexGrow={1}>
                            {`Edit ${props?.props?.blockedNumber}`}
                        </div>
                        </Tooltip>
                        <IconButton
                        style = { {color: '#99A0A8' }}
                        size='small'
                        className='closeIcon'
                        // onClick={() => toggleDrawer()}
                        ></IconButton>
                    </div>
                }
                open={openEditBlockGroupform}
                onClose={closeEditBlockgroupform}
            >
                <DataForm data={props?.props} disabled={props?.props?.disabled} />
            </OZDialog>
        </div>
    );
};

const MaterialUIPickers = connect(({ name, onChangefunction, formik, values }) => {  
    
    const [value, setValue] = useState(values?.expiryDate ? new Date(values.expiryDate).toString('DD-MM-YYYY HH:mm:ss') : null);

    const onSelect = (newValue) => {
        setValue(newValue);
        let newFormatedValue = newValue?.format("DD-MM-YYYY HH:mm:ss");
        values.expiryDate = newFormatedValue;
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={3}>
                <DateTimePicker
                    disablePast
                    value={value}
                    views={["year", "month", "day", "hours", "minutes", "seconds"]}
                    onChange={onSelect}
                    inputFormat="DD-MM-YYYY HH:mm:ss"
                    renderInput={(params) => <TextField sx={{
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '8px',
                            backgroundColor: '#ffff',
                            fontSize: '14px',
                            height: '44px',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #D6D6D7'
                        },
                        '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
                            // backgroundColor: '#D6D6D7', //TODO
                            border: '1px solid #D6D6D7'
                        }
                    }} {...params} />}
                />
            </Stack>
        </LocalizationProvider>
    );
})
export default EditExpiryDateBlockGroupForm;
