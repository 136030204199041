import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useRef } from 'react';
import { colorDataForPlot } from '../../../utils/constants';

const GraphView = ({props, fullgraph}) => {

    const chartElement = useRef(null);

    function getGraphData(props, fullgraph){
        if(props.key === "AvgTalkTime" && fullgraph?.hasOwnProperty('AvgTalkTime')){
            return fullgraph?.AvgTalkTime;
        }else if(props.key === "AvgHoldTime" && fullgraph?.hasOwnProperty('AvgHoldTime')){
            return fullgraph?.AvgHoldTime;
        }else if(props.key === "AvgWaitTime" && fullgraph?.hasOwnProperty('AvgWaitTime')){
            return fullgraph?.AvgWaitTime;
        }else if(props.key === "AvgWrapupTime" && fullgraph?.hasOwnProperty('AvgWrapupTime')){
            return fullgraph?.AvgWrapupTime;
        }else if(props.key === "AvgHandlingTime" && fullgraph?.hasOwnProperty('AvgHandlingTime')){
            return fullgraph?.AvgHandlingTime;
        }else if(props.key === "AvgSpeedToAnswer" && fullgraph?.hasOwnProperty('AvgSpeedToAnswer')){
            return fullgraph?.AvgSpeedToAnswer;
        }else if(props.key === "CallConnectionRatio" && fullgraph?.hasOwnProperty('CallConnectionRatio')){
            return fullgraph?.CallConnectionRatio;
        }else if(props.key === "WrapTimeExceeded" && fullgraph?.hasOwnProperty('WrapTimeExceeded')){
            return fullgraph?.WrapTimeExceeded;
        }else if(props.key === "AbandonedCalls" && fullgraph?.hasOwnProperty('AbandonedCalls')){
            return fullgraph?.AbandonedCalls;
        }else if(props.key === "CallAbandonedRatio" && fullgraph?.hasOwnProperty('CallAbandonedRatio')){
            return fullgraph?.CallAbandonedRatio;
        }else if(props.key === "AgentDisconnects" && fullgraph?.hasOwnProperty('AgentDisconnects')){
            return fullgraph?.AgentDisconnects;
        }
        else if(props.key === "AgentDisconnectsRatio" && fullgraph?.hasOwnProperty('AgentDisconnectsRatio')){
            return fullgraph?.AgentDisconnectsRatio;
        }
        else if(props.key === "TotalCalls" && fullgraph?.hasOwnProperty('TotalCalls')){
            return fullgraph?.TotalCalls;
        }else if(props.key === "SLA" && fullgraph?.hasOwnProperty('SLA')){
            return fullgraph?.SLA;
        }
        else if(props.key === "TotalQueuedCalls" && fullgraph?.hasOwnProperty('TotalQueuedCalls')){
            return fullgraph?.TotalQueuedCalls;
        }
        else if(props.key === "CallPerAgents" && fullgraph?.hasOwnProperty('CallPerAgents')){
            return fullgraph?.CallPerAgents;
        }else{
            return [0];
        }
    }

    function convertSecondsToCustomFormat(seconds) {
        const totalSeconds = Math.round(seconds);
        const minutes = Math.floor(totalSeconds / 60);
        const remainingSeconds = totalSeconds % 60;
    
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');
    
        return `${formattedMinutes}m ${formattedSeconds}s`;
    }

    const columnChartOptions = {
		chart: {
			type: 'column',
            // backgroundColor: '#FCFFC5',
            backgroundColor: `${props.color ? props?.color : '#ffff'}`,
            height: 55,
            width: 140,
		},
		credits: { enabled: false },
		title: { text: '' },
		xAxis: {
			title: {
				text: '',
			},
            visible: false,
			categories: ['00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00','08:00',
                '09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00',
                '18:00','19:00','20:00','21:00','22:00','23:00'
            ],
			plotLines: [
				{
					color: 'red',
					// value: liveValue,
				}
			]
		},
		yAxis: {
			title: {
				text: ''
			},
            visible: false,
		},
		legend: false,
		// tooltip: {
		// 	formatter(this: any): any {
		// 		return `${this.y}`;
		// 	}
		// },
        tooltip: {
            formatter(this: any): any {
                return `${props.key === 'AgentDisconnectsRatio' ? `Hour: ${this.x}<br/>Value: ${this.y}%` :
                        `Hour: ${this.x}<br/>Value: ${this.y}`}`
            }
        },
        plotOptions: {
            series: {
                color:`${props.key && props.key === '' ? '#006ABF' : colorDataForPlot[props?.key]}`,
                cursor: 'pointer',
                lineWidth: 1.0,
                marker:{
                    enabled : false,
                },
            }
        },
		// series: graphData
        series: [{
            name: '',
            data: getGraphData(props, fullgraph),
        }],
    };

    const lineChartOptions = {
		chart: {
			type: 'line',
            // backgroundColor: '#FCFFC5',
            backgroundColor: `${props.color ? props?.color : '#ffff'}`,
            height: 45,
            width: 140,
		},
		credits: { enabled: false },
		title: { text: '' },
		xAxis: {
			title: {
				text: '',
			},
            visible: false,
            categories: ['00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00','08:00',
                '09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00',
                '18:00','19:00','20:00','21:00','22:00','23:00'
            ],
			plotLines: [
				{
					color: 'red',
					// value: liveValue,
				}
			]
		},
		yAxis: {
			title: {
				text: ''
			},
            visible: false,
		},
		legend: false,
		// tooltip: {
		// 	formatter(this: any): any {
		// 		return `${this.y}`;
		// 	}
		// },
        tooltip: {
            formatter(this: any): any {
                return `${props.key === 'CallConnectionRatio' ? `Hour: ${this.x}<br/>Value: ${this.y}%` :
                        props.key === 'AbandonedCalls' ? `Hour: ${this.x}<br/>Value: ${this.y}` :
                        props.key === 'WrapTimeExceeded' ? `Hour: ${this.x}<br/>Value: ${this.y}` :
                        props.key === 'CallAbandonedRatio' ? `Hour: ${this.x}<br/>Value: ${this.y}%` :
                        props.key === 'AgentDisconnects' ? `Hour: ${this.x}<br/>Value: ${this.y}` :
                        props.key === 'AgentDisconnectsRatio' ? `Hour: ${this.x}<br/>Value: ${this.y}%` :
                        props.key === 'TotalCalls' ? `Hour: ${this.x}<br/>Value: ${this.y}` :
                        props.key === 'SLA' ? `Hour: ${this.x}<br/>Value: ${this.y}%` :
                        props.key === 'CallPerAgents' ? `Hour: ${this.x}<br/>Value: ${this.y}` :
                        props.key === 'TotalQueuedCalls' ? `Hour: ${this.x}<br/>Value: ${this.y}` :
                        `Hour: ${this.x}<br/>Value: ${this.y}`}`
            }
        },
        plotOptions: {
            series: {
                // color:`${props.key === 'CallConnectionRatio' ? '#4F9F52' :
                //     props.key === 'AvgWrapupTime' ? '#008DFF' :
                //     props.key === 'AbandonedCalls' ? '#F7A35C' :
                //     props.key === 'AgentDisconnects' ? '#008DFF' :
                //     props.key === 'TotalCalls' ? '#4F9F52' :
                //     props.key === 'CallPerAgents' ? '#4F9F52' :
                //     props.key === 'AbandonedCalls' ? '#008DFF' :
                //     props.key === 'CallAbandonedRatio' ? '#FFF3E9' :
                //     '#006ABF'}`,
                color:`${props.key && props.key === '' ? '#006ABF' : colorDataForPlot[props?.key]}`,
                // label: {
                //     connectorAllowed: false
                // },
                cursor: 'pointer',
                lineWidth: 1.0,
                marker:{
                    enabled : false,
                },
            }
        },
		// series: graphData
        series: [{
            name: '',
            data: getGraphData(props, fullgraph),
        }],
    };

    const options = {
        chart: {
            type: 'area',
            backgroundColor: `${props?.color ? props?.color : '#ffff'}`,
            // backgroundColor: 'rgba(230, 91, 92, 0.1)',
            // type: `${(props.key === 'AvgHoldTime' || props.key === 'AvgTalkTime' || props.key === 'AvgWaitTime' ||
            //         props.key === 'AvgWrapupTime' || props.key === 'AvgHandlingTime' || props.key === 'AvgSpeedToAnswer' || props.key === 'avgChatTime' )  ? 'area' 
            //         : 'line'}`,
            height: 55,
            width: 140,
        },
        credits: { enabled: false },
        title: { text: '' },
        xAxis: {
            allowDecimals: false,
            visible: false,
			categories: ['00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00','08:00',
				'09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00',
				'18:00','19:00','20:00','21:00','22:00','23:00'
			],
            plotLines: [
                {
                    color: '#E65B5C',
                }
            ]
        },
        yAxis: {
            title: {
                text: ''
            },
            visible: false,
        },
        // tooltip: {
        //     formatter(this: any) : any{
        //         return `${this.x}`;
        //     }
        // },
        tooltip: {
            formatter(this: any): any {
                return `${props.key === 'AvgTalkTime' ? `Hour: ${this.x}<br/>Value: ${convertSecondsToCustomFormat(this.y || 0)}` :
                        props.key === 'AvgHoldTime' ? `Hour: ${this.x}<br/>Value: ${convertSecondsToCustomFormat(this.y || 0)}` :
                        props.key === 'AvgWaitTime' ? `Hour: ${this.x}<br/>Value: ${convertSecondsToCustomFormat(this.y || 0)}` :
                        props.key === 'AvgWrapupTime' ? `Hour: ${this.x}<br/>Value: ${convertSecondsToCustomFormat(this.y || 0)}` :
                        props.key === 'AvgHandlingTime' ? `Hour: ${this.x}<br/>Value: ${convertSecondsToCustomFormat(this.y || 0)}` :
                        props.key === 'AvgSpeedToAnswer' ? `Hour: ${this.x}<br/>Value: ${convertSecondsToCustomFormat(this.y || 0)}` :
                        props.key === 'avgChatTime' ? `Hour: ${this.x}<br/>Value: ${convertSecondsToCustomFormat(this.y || 0)}` :
                        `Hour: ${this.x}<br/>Value: ${this.y}`}`
            }
        },
        plotOptions: {
            line: {
                // marker: {
                //     enabled: false
                // }
            },
            series: {
                // color:`${props.key === 'AvgHoldTime' ? '#F7A35C' :
                //         props.key === 'AvgTalkTime' ? '#E65B5C' :
                //         props.key === 'AvgWaitTime' ? '#DAEEFF' :
                //         props.key === 'AvgWrapupTime' ? '#F7A35C' :
                //         props.key === 'AvgHandlingTime' ? '#4F9F52' :
                //         props.key === 'AvgSpeedToAnswer' ? '#B2A1F9' :
                //         props.key === 'avgChatTime' ? '#B2A1F9' :
                //         '#006ABF'}`, //here we will chang the line color
                // color:`${getLineColor}`,
                color:`${props.key && props.key === '' ? '#006ABF' : colorDataForPlot[props?.key]}`,
                lineWidth: 1.5,
                marker:{
                    enabled : false,
                },
                cursor: 'pointer',
                fillColor: {
                    linearGradient: [0, 0, 0, 250],
                    // stops: [
                    //     [0.1, 'rgba(230, 91, 92, 0.1)'], // 10% - Color #E65B5C
                    //     [1, 'rgba(230, 91, 92, 1)']    // 100% - Color #E75B5C
                    // ],
                    stops: [
                        [0.1, `${props.key === 'AvgHoldTime' ? 'rgba(247, 163, 92, 1)' :
                                props.key === 'AvgWaitTime' ? 'rgba(0, 106, 191, 0.1)' :
                                props.key === 'AvgTalkTime' ? 'rgba(230, 91, 92, 0.1)' :
                                props.key === 'AvgWrapupTime' ? 'rgba(255, 243, 233, 1)' :
                                props.key === 'AvgHandlingTime' ? 'rgba(79, 159, 82, 0.1)' :
                                props.key === 'AvgSpeedToAnswer' ? 'rgba(178, 161, 249, 0.1)' :
                                props.key === 'avgChatTime' ? 'rgba(178, 161, 249, 0.1)' :
                                '#006ABF'}`], // 10% - Color #E65B5C
                        [1, `${props.key === 'AvgHoldTime' ? 'rgba(247, 163, 92, 1)' :
                                props.key === 'AvgTalkTime' ? 'rgba(230, 91, 92, 0.1)' :
                                props.key === 'AvgWaitTime' ? 'rgba(0, 106, 191, 0.1)' :
                                props.key === 'AvgWrapupTime' ? 'rgba(255, 243, 233, 1)' :
                                props.key === 'AvgHandlingTime' ? 'rgba(79, 159, 82, 0.1)' :
                                props.key === 'AvgSpeedToAnswer' ? 'rgba(178, 161, 249, 0.1)' :
                                props.key === 'avgChatTime' ? 'rgba(178, 161, 249, 0.1)' :
                                '#006ABF'}`]    // 100% - Color #E75B5C
                    ]
                },
                point: {
                    events: {
                        click: function ({ point }) {
                            // handlePointClick(point);
                        }
                    }
                }
            }
        },
        series: [{
            name:'',
            data : getGraphData(props, fullgraph)
        }],
        // series: [{
        //     name: '',
        //     data: [
        //         43934, 48656, 65165, 81827, 112143, 142383,
        //         171533, 165174, 155157, 161454, 154610
        //     ]
        // }],
        legend: false
    };

    const Dataform = ({data, fullgraph}) => {
        return (
            <>
                {(props.key === 'AvgHoldTime' || props.key === 'AvgTalkTime' || props.key === 'AvgWaitTime' ||
                    props.key === 'AvgWrapupTime' || props.key === 'AvgHandlingTime' || props.key === 'AvgSpeedToAnswer' || props.key === 'avgChatTime') ? 
                    <HighchartsReact ref={chartElement} highcharts={Highcharts} options={options} />
                : (props.key === 'CallConnectionRatio'|| props.key === 'WrapTimeExceeded' || props.key === 'AbandonedCalls' ||
                    props.key === 'AgentDisconnects' || props.key === 'TotalCalls' ||
                    props.key === 'CallPerAgents' || props.key === 'AbandonedCallsDigi' || props.key === 'TotalQueuedCalls') ?
                <>
                    <HighchartsReact ref={chartElement} highcharts={Highcharts} options={lineChartOptions} />
                </>
                :(props.key === 'CallAbandonedRatio' ||
                    props.key === 'SLA' || props.key === 'CallPerAgents' || props.key === 'AbandonedCalls' ) ?
                <>
                    <HighchartsReact ref={chartElement} highcharts={Highcharts} options={lineChartOptions} />
                </>
                :(props.key === 'AgentDisconnectsRatio')  ?
                <>
                    <HighchartsReact ref={chartElement} highcharts={Highcharts} options={columnChartOptions} />
                    {/* <HighchartsReact ref={chartElement} highcharts={Highcharts} options={columnChartOptions} /> */}
                </>
                :
                <></>
                }
            </>
        )
    }

    return (
        <div>
            <Dataform data={props} fullgraph={fullgraph}/>
        </div>
    );
}

export default GraphView;