import { Button, Grid, Typography, Box, TextField, IconButton, Tooltip, Divider } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import { Formik } from "formik";
import { useMemo, useState, useEffect } from "react";
import * as yup from "yup";
import OZDialog from "../../../components/admin/common/OZDialog";
import OZDrawer from "../../../components/admin/common/OZDrawer/OZDrawer";
import OZInput from "../../../components/admin/common/OZInput";
import { ADMIN_FORM_MODES } from "../../../utils/constants";
import CustomizedCheckbox from "../../../components/admin/common/CustomCheckBox";
import { DropzoneAreaBase } from "mui-file-dropzone";
import "../../../components/DropZone/DropZone.css";
import { addBlockGroups, addBlockGroupsAsJson, addBlockGroupsById, addBlockNumbers, getBlockNumberGroupDropdown, addBlockGroupNew, createBlockNumber, uploadBlockNumber, checkBlockNumberExist, updateBlockGroupsById } from "../../../services/api-service";
import DeleteDialog from "../../../components/admin/common/CustomDeleteDialog";
import { showErrorNotification, showInfoNotification, showSuccessNotification } from "../../../components/admin/common/NotiService";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import "./manageNumber.css";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Stack } from '@mui/system';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { connect } from "formik";
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import EditExpiryDateBlockGroupForm from "./EditExpiryDateBlockGroupForm";
import OZCheckbox from "../../../components/OZCheckbox/OZCheckbox";
import OZAutocompletes from "../../../components/admin/common/AutoComplete/OZAutocompleteGroups";
import CustomAutoComplete from "../../../components/admin/common/AutoComplete/CustomAutoComplete";
import OZFCheckbox from "../../../components/admin/common/OZFCheckbox";
import { NewAdminPages, newAuthPages, newAuthSubAdminAccess } from "../../../services/page-service";
import { ReactComponent as Edit } from '../../../static/icons/edit.svg';
import LoadingScreen from "../../../components/admin/common/OZLoadingScreen/OZLoadingScreen";

let uploadedNumbers = [];

const MaterialUIPickers = connect(({ name, onChangefunction, formik, values }) => {
  let newToDate = null;

  if (values?.expiryDate) {
    newToDate = moment(values?.expiryDate, "DD-MM-YYYY HH:mm:ss");
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [value, setValue] = useState(newToDate);

  const onSelect = (newValue) => {
    setValue(newValue);
    let newFormatedValue = newValue?.format("DD-MM-YYYY HH:mm:ss");
    values.expiryDate = newFormatedValue;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={3}>
        <DateTimePicker
          disablePast
          // disableFuture
          value={value}
          views={["year", "month", "day", "hours", "minutes", "seconds"]}
          onChange={onSelect}
          inputFormat="DD-MM-YYYY HH:mm:ss"
          renderInput={(params) => <TextField sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
              backgroundColor: '#ffff',
              fontSize: '14px',
              height: '44px',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #D6D6D7'
            },
            '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
              // backgroundColor: '#D6D6D7', //TODO
              border: '1px solid #D6D6D7'
            }
          }} {...params} />}
        />
      </Stack>
    </LocalizationProvider>
  );
})

function CheckboxList(checkListProps) {
  console.log("checkListProps", checkListProps)
  const [openEditForm, setOpenEditForm] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [loading, setLoading] = useState(false);

  const formButtonStyle = useMemo(
    () => ({
      borderRadius: '8px',
      fontWeight: '400',
      fontSize: '14px',
    }),
    []
  );

  function onCloseGroupEdit(){
    setOpenEditForm(false); 
    setSelectedRow({});
  }

  async function updateBlockGroup(data) {
    try {
      setLoading(true);
      await updateBlockGroupsById(data)
        .then((resp) => {
          console.log("resp", resp)
          if (resp?.Status === "Success") {
            showSuccessNotification(`Block Group: ${data.name} Updated Successfully`);
            onCloseGroupEdit();
            checkListProps.expUpdate && checkListProps.expUpdate(checkListProps.data.id);
            checkListProps.updateBlockGroup && checkListProps.updateBlockGroup({})
          }
          else {
            showErrorNotification(resp?.Message?.name || resp?.Message?.description || resp?.Message);
          }
          setLoading(false);
        }).catch((e) => {
          console.log(e)
          showErrorNotification(JSON.stringify(e));
          setLoading(false);
        });
    } catch (e) {
      console.log(e)
      showErrorNotification(JSON.stringify(e));
      setLoading(false);
    }
  }

  const editValidationSchema = useMemo(
    () =>
      yup.object({
        name: yup
          .string("Enter Group Name*")
          .required("Group Name is required."),
        description: yup
          .string("Enter Description*")
          .nullable()
      }),
    []
  );

  return (
    <div>
      {checkListProps?.blockgroups?.length > 0 && (
        <Box
          sx={{
            alignItems: "center",
            borderLeft: '0.0625rem solid #E6E5E6',
            borderBottom: '0.0625rem solid #E6E5E6',
            borderRight: '0.0625rem solid #E6E5E6',
            borderBottomLeftRadius: "0.25rem",
            borderBottomRightRadius: "0.25rem",
            borderTop: '0.0625rem solid #E6E5E6',
            borderTopLeftRadius: "0.25rem",
            borderTopRightRadius: "0.25rem",
            bgcolor: "#F6F7F9",
            maxHeight: "250px",
            overflowX: "hidden !important",
            overflowY: "scroll"

          }}>
          {checkListProps?.blockgroups?.map((blockGroupObject, index) => {
            return (
              <>
                <Stack key={index} container spacing={2} display='flex' direction={'row'}>
                  <Grid item xs={7}>
                    <ListItemButton
                      sx={{
                        height: '3.75rem',
                        width: '22.2rem',
                      }}>
                      <Stack>
                        <Typography sx={{ fontSize: '14px', color: '#212121' }}>{blockGroupObject?.name}</Typography>
                        <Typography sx={{ fontSize: '13px', color: '#99A0A8', opacity: 0.7 }}>{blockGroupObject?.description}</Typography>
                        {(newAuthPages(NewAdminPages.MANAGE_NUMBERS) || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'Full') || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'Edit')) &&
                          <IconButton className="oz-block-group-edit-button" onClick={() => { setSelectedRow(blockGroupObject); setOpenEditForm(true);}}>
                            <Edit />
                          </IconButton>
                        }
                      </Stack>
                    </ListItemButton>
                  </Grid>
                </Stack>
                <span style={{ flexGrow: 1, alignSelf: "flex-end" }}>
                  <Divider variant="fullWidth" sx={{ borderColor: "#E6E5E6" }} />
                </span>
              </>
            );
          })}
        </Box>
      )}
      {openEditForm && (
        <div>
          <OZDialog
            hideActions
            title={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Tooltip title={selectedRow?.name}><div
                  className="oz-managenumbers-drawer-title-heading"
                  sx={{
                    fontWeight: 600,
                    fontSize: "1.125rem",
                    width: "11rem",
                    marginLeft: "11px",
                    marginTop: '1px'
                  }}
                  flexGrow={1}>
                  {`Edit ${selectedRow?.name}`}
                </div>
                </Tooltip>
                <IconButton
                  style={{ color: '#99A0A8' }}
                  size='small'
                  className='closeIcon'
                // onClick={() => toggleDrawer()}
                ></IconButton>
              </div>
            }
            open={openEditForm}
            onClose={onCloseGroupEdit}
          >
            <Formik
              initialValues={selectedRow}
              validationSchema={editValidationSchema}
              onSubmit={(values) => {
                console.log("values in submit", values)
                updateBlockGroup(values);
              }}
            >
              {(props) => (
                <form
                  onSubmit={props.handleSubmit}
                  id="blockNumberEdit-Form" className='ozcw-scrollbar' style={{
                    maxHeight: '70vh',
                    padding: '1px'
                  }}>
                  <div style={{ width: '22rem', minHeight: '18rem' }}>
                    <Grid container spacing={2} direction='column'>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <OZInput
                          name="name"
                          label="Group Name *"
                          placeholder="Enter Name"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: '15px' }}>
                        <OZInput
                          name="description"
                          label="Group Description *"
                          placeholder="Enter Description"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} direction={'row'} flexGrow={1} flex sx={{ marginTop: 7, paddingBottom: '10px' }} justifyContent='end'>
                      <Grid item>
                        <Button style={formButtonStyle} onClick={onCloseGroupEdit} variant="outlined" type="submit">
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item >
                        <Button style={formButtonStyle} variant="contained" type="submit" form="blockNumberEdit-Form">
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                  {loading ? (
                    <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
                      <LoadingScreen />
                    </div>
                  ) :
                    (<></>)
                  }
                </form>
              )}
            </Formik>
          </OZDialog>
        </div>
      )}
    </div>
  );
}

function ViewGroupNumberForm(viewGroupProps) {

  { console.log("viewGroupProps", viewGroupProps) }
  let styleFieldHeader = useMemo(
    () => ({
      fontSize: "0.75rem",
      height: '0.875rem',
      color: '#99A0A8',
      lineHeight: "0.875rem",
    }),
    []
  );

  let styleFieldValue = useMemo(
    () => ({
      height: '1rem',
      fontWeight: 400,
      fontSize: "0.875rem",
      color: '#212121',
      lineHeight: "1rem",
    }),
    []
  );

  let basicStyle = useMemo(
    () => ({
      fontSize: '0.75rem', fontWeight: 400, color: "#99A0A8"
    }),
    []
  );

  function getExpiryTimeDisplay(blockGroupObject) {
    if (blockGroupObject?.expiryDate !== null) {
      let value = moment(blockGroupObject?.expiryDate ? blockGroupObject?.expiryDate : null);
      if (value !== null) {
        return `${value.format("DD-MM-YYYY HH:mm:ss")}`;
      }
    } else {
      return `Null`;
    }
  }

  return (
    <div className="oz-add-block-group-form-view-container">
      <Grid container={true} >
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ ...styleFieldHeader, width: "5.5625rem", }}>
          Expiry Date
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ ...styleFieldValue, width: "6.625rem", marginTop: '0.5rem' }} >          
          {getExpiryTimeDisplay(viewGroupProps.data)}
        </Grid>
      </Grid>
      <div className="oz-add-block-group-form-view-inner-container">
        <Typography sx={basicStyle}>Groups ({viewGroupProps?.data?.blockgroups?.length})</Typography>
      </div>
      <CheckboxList openAddBlockNumberDialog={viewGroupProps.openAddBlockNumberDialog} blockgroups={viewGroupProps.data.blockgroups} mode='view' {...viewGroupProps} />
    </div >
  );
}

const CloudUploadOutlinedIcon = () => {

  return (<img className="oz-block-group-upload-file-image"
    src={`${process.env.PUBLIC_URL}/icons/upload_file.svg`}
    alt="upload"
  ></img>)
};

const AddBlockGroupForm = (blockGroupFormMainProps) => {
  console.log("blockGroupFormMainProps", blockGroupFormMainProps)
  let initMode = blockGroupFormMainProps.data ? ADMIN_FORM_MODES.view : ADMIN_FORM_MODES.add;
  const [openBlockAddForm, setOpenBlockAddFormState] = useState(true);
  const [blockGroupFormMode, setBlockGroupFormModeState] = useState(initMode);
  const [openOnlyNumbers, setOpenOnlyNumbers] = useState(false)
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [valid, setValid] = useState(true);
  const [blockgroup, setBlockGroup] = useState([]);

  let buttonStyle = useMemo(() => ({
    borderRadius: '0.5rem',
    height: '2.75rem',
    fontSize: '0.875rem',
    width: '5.6875rem',
    fontWeight: 400
  }), [])

  let cancelButtonStyle = useMemo(
    () => ({
      height: '2.75rem',
      width: "5.6875rem",
      borderRadius: '0.5rem',
      fontSize: "0.875rem",
      fontWeight: 400,
      color: '#3D8BF8',
      border: '0.0625rem solid #008DFF',
      marginRight: '0.9375rem'
    }),
    []
  );

  const validationSchema = useMemo(
    () =>
      yup.object({
        name: yup
          .string("Enter Group Name*")
          .nullable()
          .when('blockGroups', {
            is: (blockGroups) => !blockGroups || blockGroups.length === 0,
            then: yup
            .string("Enter Group Name*")
            .nullable()
            .min(2, "Group Name should be between 2 to 50 characters.")
            .max(50, "Group Name should be between 2 to 50 characters.")
            .matches(
              /^([A-Za-z]+((_|\s)?[A-Za-z\d])*)$/,
              'Group Name seems to be invalid.'
            )
            .required("Group Name is required."),
          }),
        description: yup
          .string("Enter Description*")
          .nullable()
          .when('blockGroups', {
            is: (blockGroups) => !blockGroups || blockGroups.length === 0,
            then: yup
            .string("Enter Description*")
            .nullable()
            .min(2, "Description should be between 2 to 100 characters.")
            .max(100, "Description should be between 2 to 100 characters.")
            .required("Description is required"),
        })       
      }),
    []
  );

  function updateUploadedNumber(data) {
    uploadedNumbers = data
  }

  const clearLocalStorageForBlockNumberForm = () => {
    localStorage.removeItem("BlockNumberString");
    localStorage.removeItem("expiryDate");
    localStorage.removeItem("checkUpdateExpiryAdd");
    
  }

  const clearLocalStorageForBlockGroupForm = () => {
    localStorage.removeItem("BlockGroupDescription");
    localStorage.removeItem("BlockGroupName");
    localStorage.removeItem("BlockGroups");
  }


  useEffect(() => {
    getBlocknumberGroupOptions()
  }, []);

  const [blockedNumberExist, setBlockedNumberExist] = useState(false);

  async function isBlockedNumberExist(blockedNumber){
    const apiResponse = await checkBlockNumberExist(blockedNumber);

    if (apiResponse && apiResponse?.isBlockedNumberExist == true) {
      console.log("inside")
      console.log("apiResponse.isBlockedNumberExist",apiResponse.isBlockedNumberExist)
      setBlockedNumberExist(true);
      return true;
    } else {
      setBlockedNumberExist(false);
      return false;
    }
  }

  async function getBlocknumberGroupOptions() {
    try {
      getBlockNumberGroupDropdown().then(resp => {
        setBlockGroup(resp.Data);
      }).catch(e => {
        showErrorNotification('No BlockNumber Group Data, Check your Internet Connection');
        setBlockGroup([]);
      })
    }
    catch (e) {
      showErrorNotification(JSON.stringify(e));
    }
  }

  async function saveBlockedNumber(data, date, isUpdate) {
    console.log("uploaded",uploadedNumbers.length)
    console.log("data check save block",data,date,isUpdate)

    localStorage.setItem("BlockNumberString", data ? data : "");
    localStorage.setItem("expiryDate", date ? date : "");
    localStorage.setItem("checkUpdateExpiryAdd", isUpdate === true || isUpdate === 'true' ? true : false);

    if(!uploadedNumbers.length>0){
      if(data == null){
        showErrorNotification("Blocked Number cant be empty");
      }else if(isUpdate == true){
        showErrorNotification("Update checkbox is only applicable when file is uploaded");
      }else{
        const check = await isBlockedNumberExist(data);
        if(!check){
          setShowBlockNumberAddForm(false);
        }
        else{
          showErrorNotification(data+" Block Number already exists");
        }
      }
    }else{
      if ((data !== null && data !== "" && data != undefined) || (date !== null && date !== "" && date != undefined)) {
        showErrorNotification("Please select either Block Number details or upload the file. Both cannot be selected")
      }
      else{
          setShowBlockNumberAddForm(false);
      }
    }
  }

  const [showBlockNumberAddForm, setShowBlockNumberAddForm] = useState(true);

  function onClickPreviousButton(value) {
    setShowBlockNumberAddForm(true)
    console.log("va;ue",value)
    localStorage.setItem("BlockGroups", value?.blockGroups ? JSON.stringify(value.blockGroups) : JSON.stringify([]))
    localStorage.setItem("BlockGroupName", value?.name ? value.name : "")
    localStorage.setItem("BlockGroupDescription", value?.description ? value.description : "")
  }

  function openAddBlockNumberDialog() {
    setOpenOnlyNumbers(true)
  }
  const closeBlockGroupForm = () => {
    blockGroupFormMainProps.onClose();
    uploadedNumbers = []
    setOpenBlockAddFormState(false);
  };

  async function addBlockNumberInAdd(formData) {
    console.log("formData", formData)
    const BlockGroupIds = [];

    //creating new group
    if (formData.name && formData.description) {
      try {
        const blockGroupToCreate = {name:formData.name,description:formData.description}
        
        await addBlockGroupNew(blockGroupToCreate)
          .then((resp) => {
            if (resp?.Status === "Success") {
              BlockGroupIds.push({ id: resp?.Data?.id });
              getBlocknumberGroupOptions();
              clearLocalStorageForBlockGroupForm();
              showSuccessNotification(`Block Group: ${formData.name} Created Successfully`);
            }
            else {
              // console.log(resp.Message)
              // showErrorNotification(JSON.stringify(resp.Message));
              showErrorNotification(resp?.Message?.name || resp?.Message?.description || resp?.Message);
            }
          })
          .catch((e) => {
            console.log(e)
            showErrorNotification(JSON.stringify(e));
          });
      } catch (e) {
        console.log(e)
        showErrorNotification(JSON.stringify(e));
      }
    }else if(formData?.blockGroups?.length>0){
      formData.blockGroups.forEach(item => {
        BlockGroupIds.push({ id: item.id });
      });
    } else {
      showErrorNotification('Group details not found');
    }

    if(BlockGroupIds?.length >0){
      if (uploadedNumbers?.length > 0) {
        const fileName = uploadedNumbers[0]?.file.name;
        const groupIds = BlockGroupIds.length === 1 ? BlockGroupIds[0].id : BlockGroupIds.map(item => item.id).join(',');
        
        var form = new FormData();
        form.append("file", uploadedNumbers[0].file, "AddBlockNumber.xls");
        form.append("data", groupIds);
        try {
          const blockNumberData = {isUpdate:formData.checkUpdateExpiryAdd,body:form}
          await uploadBlockNumber(blockNumberData)
            .then((resp) => {
              if (resp?.Status === "Success") {
                clearLocalStorageForBlockNumberForm();
                if (blockGroupFormMainProps.currentTab === '4') {                
                  blockGroupFormMainProps.updateBlockGroups({});
                }
                closeBlockGroupForm();
                showSuccessNotification(`Block Number: ${formData.blockedNumber} Added Successfully`);
              }
              else {
                // console.log(resp.Message)
                // showErrorNotification(JSON.stringify(resp.Message));
                showErrorNotification(resp?.Message?.name || resp?.Message?.description || resp?.Message);
              }
            })
            .catch((e) => {
              console.log(e)
              showErrorNotification(JSON.stringify(e));
            });
        } catch (e) {
          console.log(e)
          showErrorNotification(JSON.stringify(e));
        }
      }
      else{
        try {
          const blockNumberData = {blockedNumber:formData?.blockedNumber,expiryDate:formData?.expiryDate,blockgroups:BlockGroupIds}
          const dataToApi = {body: blockNumberData, isUpdate: true}
          
          await createBlockNumber(dataToApi)
            .then((resp) => {
              if (resp?.Status === "Success") {
                clearLocalStorageForBlockNumberForm();
                if (blockGroupFormMainProps.currentTab === '4') {
                  blockGroupFormMainProps.updateBlockGroups({});
                }
                
                uploadedNumbers = []
                closeBlockGroupForm();
                showSuccessNotification(`Block Number: ${formData.blockedNumber} Added Successfully`);
              }
              else {
                // console.log(resp.Message)
                // showErrorNotification(JSON.stringify(resp.Message));
                showErrorNotification(resp?.Message?.name || resp?.Message?.description || resp?.Message);
              }
            })
            .catch((e) => {
              console.log(e)
              showErrorNotification(JSON.stringify(e));
            });
        } catch (e) {
          console.log(e)
          showErrorNotification(JSON.stringify(e));
        }
      }
    }
  }


  async function addBlockNumber(formData) {
    if (uploadedNumbers.length > 0) {
      const fileName = uploadedNumbers[0]?.file.name
      var form = new FormData();
      form.append("file", uploadedNumbers[0].file, "AddBlockNumber.xls");
      try {
        addBlockNumbers(form)
          .then((resp) => {
            if (resp.Status === "Success") {
              showSuccessNotification(`${resp.Message} in Block Group : ${formData.name}`);
              delete formData.name;
              // formData.blockNumbers = resp.Data //commented for testing purpose
              if (blockGroupFormMode === ADMIN_FORM_MODES.add) {
                formData.blockNumbers = resp.Data
                if (formData.blockNumbers !== null) {
                  try {
                    addBlockGroups(formData)
                      .then((resp) => {
                        if (resp.Status === "Success") {
                          // localStorage.clear()
                          clearLocalStorageForBlockGroupForm();
                          if (blockGroupFormMainProps.currentTab === '4') {
                            blockGroupFormMainProps.updateBlockGroups({});
                          }
                          closeBlockGroupForm();
                          showSuccessNotification(`Block Group : ${formData.name} Added Successfully`)
                        }
                        else {
                          // console.log(resp.Message)
                          // showErrorNotification(JSON.stringify(resp.Message));
                          showErrorNotification(resp?.Message?.name || resp?.Message?.description || resp?.Message);
                        }
                      })
                      .catch((e) => {
                        console.log(e)
                        showErrorNotification(JSON.stringify(e));
                      });
                  } catch (e) {
                    console.log(e)
                    showErrorNotification(JSON.stringify(e));
                  }
                }
                else {
                  showErrorNotification("block number not available")
                }
              }
              else {
                let blockNums = formData.blockNumbers;
                let combinedBlockNum = [...blockNums, ...resp.Data]
                formData.blockNumbers = combinedBlockNum
                try {
                  addBlockGroupsById(formData)
                    .then((resp) => {
                      if (resp.Status === "Success") {
                        setBlockGroupFormModeState("View")
                        setOpenOnlyNumbers(false);
                        // localStorage.clear()
                        clearLocalStorageForBlockGroupForm();
                        showSuccessNotification(`Block Group: ${formData.name} Updated Successfully`)
                        // blockGroupFormMainProps.updateBlockGroup();
                        blockGroupFormMainProps.expUpdate(formData.id);
                      }
                      else {
                        // console.log(resp.Message)
                        // showErrorNotification(JSON.stringify(resp.Message));
                        showErrorNotification(resp?.Message?.name || resp?.Message?.description || resp?.Message);
                      }
                    }
                    )
                    .catch((e) => {
                      console.log(e)
                      showErrorNotification(JSON.stringify(e));
                    });
                } catch (e) {
                  console.log(e)
                  showErrorNotification(JSON.stringify(e));
                }
              }
              showSuccessNotification(`File: ${fileName} Uploaded Successfully`)
            }
            else {
              showErrorNotification(JSON.stringify(resp.Message));
            }
          })
          .catch((e) => {
            showErrorNotification(JSON.stringify(e));
          });
      } catch (e) {
        showErrorNotification(JSON.stringify(e));
      }
    }
    else {
      try {
        let blockedNumber = {};
        blockedNumber.blockedNumber = localStorage.getItem("BlockNumberString");
        // console.log("here comes in update", localStorage.getItem('expiryDate'));
        blockedNumber.expiryDate = localStorage.getItem('expiryDate');
        // console.log("here calling API in Update", blockedNumber)

        // let blockedNumber = {};
        // blockedNumber.blockedNumber = localStorage.getItem("BlockNumberString");
        // let localDate = localStorage.getItem("expiryDate");
        // let PaExpiryDate = moment(localDate, 'DD-MM-YYYY HH:mm:ss');
        // console.log("here now", localStorage.getItem("expiryDate"));
        // console.log("here comes", PaExpiryDate);
        // let convertedDate = PaExpiryDate.format("YYYY-MM-DD HH:mm:ss");
        // console.log("now here comes", convertedDate);
        // blockedNumber.expiryDate = convertedDate;
        // console.log("here calling API", blockedNumber)
        addBlockGroupsAsJson(blockedNumber)
          .then((resp) => {
            if (resp.Status === "Success") {
              showSuccessNotification(`${resp.Message} in Block Group : ${formData.name}`);
              delete formData.name;
              // showSuccessNotification(`Block Numbers: ${localStorage.getItem("BlockNumberString")} Added Successfully`)
              if (blockGroupFormMode === ADMIN_FORM_MODES.add) {
                formData.blockNumbers = resp.Data
                if (formData.blockNumbers !== null) {
                  try {
                    addBlockGroups(formData)
                      .then((resp) => {
                        if (resp.Status === "Success") {
                          // localStorage.clear()
                          clearLocalStorageForBlockGroupForm();
                          if (blockGroupFormMainProps.currentTab === '4') {
                            blockGroupFormMainProps.updateBlockGroups({});
                          }
                          closeBlockGroupForm();
                          showSuccessNotification(`Block Group: ${formData.name} Added Successfully`)
                        }
                        else {
                          // console.log(resp.Message)
                          // showErrorNotification(JSON.stringify(resp.Message));
                          showErrorNotification(resp?.Message?.description || resp?.Message?.name || resp?.Message);
                        }
                      })
                      .catch((e) => {
                        console.log(e)
                        showErrorNotification(JSON.stringify(e));
                      });
                  } catch (e) {
                    console.log(e)
                    showErrorNotification(JSON.stringify(e));
                  }
                }
                else {
                  showErrorNotification("block number not available")
                }
              }
              else {
                let blockNums = formData.blockNumbers;
                let combinedBlockNum = [...blockNums, ...resp.Data]
                formData.blockNumbers = combinedBlockNum
                try {
                  addBlockGroupsById(formData)
                    .then((resp) => {
                      if (resp.Status === "Success") {
                        setBlockGroupFormModeState("View")
                        setOpenOnlyNumbers(false);
                        // localStorage.clear()
                        clearLocalStorageForBlockGroupForm();
                        showSuccessNotification(`Block Group: ${formData.name} Updated Successfully`)
                        // blockGroupFormMainProps.updateBlockGroup();
                        blockGroupFormMainProps.expUpdate(formData.id);
                      }
                      else {
                        // console.log(resp.Message)
                        // showErrorNotification(JSON.stringify(resp.Message));
                        showErrorNotification(resp?.Message?.name || resp?.Message?.description || resp?.Message);
                      }
                    }
                    )
                    .catch((e) => {
                      console.log(e)
                      showErrorNotification(JSON.stringify(e));
                    });
                } catch (e) {
                  console.log(e)
                  showErrorNotification(JSON.stringify(e));
                }
              }
            }
            else {
              // console.log(resp.Message)
              // showErrorNotification(JSON.stringify(resp.Message));
              showErrorNotification(resp?.Message?.name || resp?.Message?.description || resp?.Message);
            }
          })
          .catch((e) => {
            console.log(e)
            showErrorNotification(JSON.stringify(e));
          });
      } catch (e) {
        console.log(e)
        showErrorNotification(JSON.stringify(e));
      }
    }
    uploadedNumbers = []
  }

  const getValidationInViewMode = (e, value) => {
    blockGroupFormMainProps.data.blockedNumber = value;
    const regex = /^([A-Za-z]+((_|\s)?[A-Za-z\d])*)$/;
    const isMatch = regex.test(value);

    const digitCount = value.length;

    // Check the conditions for validation
    const isValidLength = value.length >= 2 && value.length <= 50;
    const isValidDigitCount = digitCount >= 2 && digitCount <= 50;

    // Final validation check
    if (isMatch && isValidLength && isValidDigitCount) {
      setValid(true);
    } else {
      setValid(false);
    }
  }

  const DataForm = (formProps) => {
    console.log("in data form", formProps)
    
    console.log("update",localStorage.getItem("checkUpdateExpiryAdd"))
    let initData = formProps.data || {
      blockNumbers: [],
      blockGroups: !showBlockNumberAddForm ? (localStorage.getItem("BlockGroups") !== null ?JSON.parse(localStorage.getItem("BlockGroups")) : []):[],
      expiryDate: (showBlockNumberAddForm || localStorage.getItem(("expiryDate")) !== null) ? localStorage.getItem("expiryDate") : null,
      description: !showBlockNumberAddForm ? localStorage.getItem("BlockGroupDescription") : "",
      name: !showBlockNumberAddForm ? localStorage.getItem("BlockGroupName") : "",
      blockedNumber: (showBlockNumberAddForm || localStorage.getItem(("BlockNumberString")) !== null) ? localStorage.getItem("BlockNumberString") : "",
      checkUpdateExpiryAdd: (localStorage.getItem('checkUpdateExpiryAdd') === true || localStorage.getItem('checkUpdateExpiryAdd') === 'true') ? true : false

    };
    const [fileUploaded, setUpload] = useState(uploadedNumbers.length > 0 ? true : false)
    const [fileObjsUploaded, setFileObjsUploaded] = useState(uploadedNumbers.length > 0 ? uploadedNumbers : [])
    const maxSize = 25 * 1024 * 1024;

    const onDownload = () => {
      const link = document.createElement("a");
      link.download = `block_unblock_number_sample.xls`;
      link.href = `${process.env.PUBLIC_URL}/sample_files/block_unblock_number_sample.xls`;
      link.click();
    };

    function checkVariant(message, variant) {
      if (variant === "success") {
        showSuccessNotification(`${(variant[0].toUpperCase() + variant.slice(1))}: ${message}`);
        setUpload(true)
      }
      else if (variant === "error") {
        showErrorNotification(`${(variant[0].toUpperCase() + variant.slice(1))}: ${message}`);
      }
      else {
        showInfoNotification(`${(variant[0].toUpperCase() + variant.slice(1))}: ${message}`);
        setUpload(false)
      }
    }

    function SuccessIcon() {
      return (
        <IconButton sx={{ marginTop: "20px" }}>
          <CheckCircleIcon sx={{
            color: "#3DABF8",
          }} />
        </IconButton>
      )
    }

    return (
      <div className="ozBlockGroupFormAddForm">
        <Formik
          enableReinitialize
          initialValues={initData}
          validationSchema={validationSchema}
          onSubmit={(formValues) => {
            console.log("here coming on add", formValues.values);
            let flag = 0;
            if (formValues.expiryDate === 'Invalid Date') {
              flag = 1;
              showErrorNotification("Invalid Expiry date, Please check the date passed");
              return;
            }
            if (formValues.expiryDate === "" || formValues.expiryDate === null || formValues.expiryDate === 'Invalid Date') {
              formValues.expiryDate = null;
            } else if (formValues.expiryDate !== null || formValues.expiryDate !== "" || formValues.expiryDate !== 'Invalid Date') {
              const moment = require('moment');
              let expiryDate = formValues.expiryDate;
              let CDate = moment().format('YYYY-MM-DD HH:mm:ss');
              let parsedExpiryDate;
              if (moment(expiryDate, 'DD-MM-YYYY HH:mm:ss', true).isValid()) {
                parsedExpiryDate = moment(expiryDate, 'DD-MM-YYYY HH:mm:ss');
              } else if (moment(expiryDate, 'YYYY-MM-DD HH:mm:ss', true).isValid()) { // Check if expiryDate matches 'YYYY-MM-DD HH:mm:ss' format
                parsedExpiryDate = moment(expiryDate, 'YYYY-MM-DD HH:mm:ss');
              } else {
                // Handle invalid date format
                console.error('Invalid date format:', expiryDate);
                showErrorNotification("Expiry date cannot be less than the Current Date Time");
                return;
              }
              // Format the parsed date to the desired format 'YYYY-MM-DD HH:mm:ss'
              let EEPirydate = parsedExpiryDate.format('YYYY-MM-DD HH:mm:ss');
              const timestampCDate = new Date(CDate).getTime();
              const timestampEEPiryDate = new Date(EEPirydate).getTime();
              //new doe for testing purpose:::::::::::://
              if (timestampCDate > timestampEEPiryDate) {
                flag = 1;
                showErrorNotification("Expiry date cannot be less than the Current Date Time");
                return;
              } else {
                formValues.expiryDate = EEPirydate;
              }
            }
            if (!formValues.blockGroups.length > 0 && !formValues.name && !formValues.description) {
              flag = 1;
              showErrorNotification("Please select any Group Details")
              return;
            }

            if(formValues.name || formValues.description) {
              if (formValues.blockGroups.length > 0) {
                flag = 1;
                showErrorNotification("Please select either New Group or Existing Group Details. Both cannot be selected")
                return;
              }
            }
            if (!formValues.blockGroups.length > 0) {
              if (formValues.name && !formValues.description) {
                flag = 1;
                showErrorNotification("Please enter Group Description")
                return;
              } else if (!formValues.name && formValues.description) {
                flag = 1;
                showErrorNotification("Please enter Group name")
                return;
              }
            }
            if (flag === 0) {
              // addBlockNumber(formValues); //this commented as having issue in converting date to desired format, for now testing
              addBlockNumberInAdd(formValues);
            }
          }} >
          {(blockGroupFormProps) => (
            <form
              onSubmit={blockGroupFormProps.handleSubmit}
              id="blockGroupForm"
              className={"ozcwScrollbar oz-add-phone-number-form-container " + (blockGroupFormMode === ADMIN_FORM_MODES.edit ? "oz-add-block-group-form-edit-container" : "oz-add-block-group-form-view-container")}
              style={{
                width: "29rem",
                height: (showBlockNumberAddForm || openOnlyNumbers) ? '26rem' : '20.4rem',
                marginTop: showBlockNumberAddForm ? "-12px" : "15px"
              }}>
              <div>
                <Grid container={true} spacing={2} alignItems="center"
                  justifyContent="center">
                  {((blockGroupFormMode === "Create" && showBlockNumberAddForm)) &&
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <OZInput
                        name="blockedNumber"
                        label="Block Number *"
                        onBlur={(event) => {
                          const value = event.target.value;
                          localStorage.setItem("BlockNumberString", value ? value : "");
                          setBlockedNumberExist(false)
                      }}
                        placeholder="Enter Number [ Ex. 9937XXXXXX, 9439XXXXXX, 8125XXXXXX ]"
                        error={blockedNumberExist}
                        helperText={blockedNumberExist ? "This Block Number already exists" : ""}
                      />
                    </Grid>}
                  {((blockGroupFormMode === "Create" && showBlockNumberAddForm)) &&
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <label style={{ fontWeight: 400, fontSize: "12px" }}>Expiry Date</label>
                      <MaterialUIPickers name="expiryDate" values={blockGroupFormProps?.values} />
                    </Grid>
                  }

                  {((blockGroupFormMode === "Create" && showBlockNumberAddForm)) &&
                    <Grid item sx={{ color: "#99A0A8", fontSize: "1.125rem", fontWeight: 400, }}>
                      OR
                    </Grid>}

                  {((blockGroupFormMode === "Create" && showBlockNumberAddForm)) &&
                    <>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <DropzoneAreaBase
                          maxFileSize={maxSize}
                          Icon={fileUploaded ? SuccessIcon : CloudUploadOutlinedIcon}
                          dropzoneParagraphClass="dropzone-text"
                          dropzoneClass="drop-zone-area"
                          previewGridProps={{
                            item: {
                              position: 'absolute',
                              display: 'flex',
                              overflow: "hidden",
                              //flexWrap: "wrap",
                              alignItems: 'end',
                              height: '40px',
                            },
                            container: { spacing: 1, direction: 'row' }
                          }}
                          dropzoneText={fileUploaded ? "" :
                            <span>Drag and Drop the file to upload or <span style={{ color: '#3D8BF8', fontSize: '14px', fontWeight: '500', cursor: 'pointer' }}>Browse</span></span>}
                          acceptedFiles={['application/vnd.ms-excel']}
                          onAdd={(files) => {
                            if (files?.length > 0) {
                              setFileObjsUploaded([files[0]])
                            }
                            updateUploadedNumber(files);
                          }}
                          onDrop={(files) => { console.log("ondrop", files) }}
                          onAlert={(message, variant) => { checkVariant(message, variant); }}
                          initialFiles={uploadedNumbers}
                          onDelete={(fileObj) => { console.log('Removed File:', fileObj); uploadedNumbers = []; setFileObjsUploaded([]) }}
                          fileObjects={fileObjsUploaded}
                          filesLimit={3}
                          useChipsForPreview={true}
                          previewText="Selected files"
                          showAlerts={false}
                        />
                        <div style={{marginTop:'10px'}}>
                        <OZFCheckbox
                            name="checkUpdateExpiryAdd"
                            label="Update the existing expiry date across block number groups"
                            labelPosition="end"
                        />
                        </div>
                      </Grid>

                      <Grid item class='oz-note' sx={{ fontWeight: 400, textAlign: 'left' }}>
                        <div style={{ fontSize: '14px', fontWeight: '400', paddingTop: '10px', marginLeft: '5px' }}>
                          <b>Note:</b>
                          <div style={{ paddingTop: '10px' }}>*A phone number can only have single expiry date across block number groups</div>
                          <div style={{ paddingTop: '10px' }}>*File should be XLS Format. To download sample
                            <span style={{ color: '#3D8BF8', fontSize: '14px', fontWeight: '500', cursor: 'pointer' }} onClick={onDownload}> Click Here.</span>
                          </div>
                        </div>
                      </Grid>

                    </>
                  }
                </Grid>

                {((blockGroupFormMode === "Create" && showBlockNumberAddForm)) &&
                  <Grid container={true} spacing={1} justifyContent={"flex-end"} paddingTop={"2rem"} paddingBottom={"1rem"}>
                    <Grid item >
                      <Button
                        variant="outlined"
                        sx={cancelButtonStyle}
                        onClick={openOnlyNumbers ? () => { setOpenOnlyNumbers(false); clearLocalStorageForBlockGroupForm();clearLocalStorageForBlockNumberForm(); } : () => { closeBlockGroupForm(); clearLocalStorageForBlockGroupForm();clearLocalStorageForBlockNumberForm(); }}>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      {console.log("blockGroupFormProps.values.name", blockGroupFormProps.values)}
                      <Button
                        variant="contained"
                        sx={buttonStyle}
                        onClick={() => saveBlockedNumber(blockGroupFormProps.values.blockedNumber, blockGroupFormProps.values.expiryDate,blockGroupFormProps.values.checkUpdateExpiryAdd)}
                      >
                        Next
                      </Button>
                    </Grid>
                  </Grid>}
                <Grid container={true} spacing={2}>
                  {!showBlockNumberAddForm && blockGroupFormMode === ADMIN_FORM_MODES.add &&
                    <>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div class='oz-grid-group'>
                          <div style={{ fontSize: '14px', fontWeight: '1000', paddingBottom: '15px' }}>
                            Create New Group
                          </div>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <OZInput
                              name="name"
                              label="Group Name *"
                              placeholder="Enter Name"
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: '15px' }}>
                            <OZInput
                              name="description"
                              label="Group Description *"
                              placeholder="Enter Description"
                            />
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item sx={{ color: "#99A0A8", fontSize: "1.125rem", fontWeight: 400, marginLeft: "45%" }}>
                        OR
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div class='oz-grid-group'>
                          <div style={{ fontSize: '14px', fontWeight: '1000', paddingBottom: '15px' }}>
                            Add to Existing Group
                          </div>
                          <Stack>
                            <CustomAutoComplete
                              mode='Add'
                              limitTags={2}
                              name="blockGroups"
                              labelHeader='Groups *'
                              placeholder={"Select Groups"}
                              Datas={blockgroup}
                              optionLabelName='name'
                              showCheckBox={true}
                              multiple={true}
                              showselectall="true"
                              popupIcon={null}
                              showAvatar={true}
                            />
                          </Stack>
                        </div>
                      </Grid>
                    </>
                  }

                  {blockGroupFormMode === ADMIN_FORM_MODES.add && !showBlockNumberAddForm &&
                    <Grid item>
                      <Button onClick={() => { onClickPreviousButton(blockGroupFormProps.values) }} sx={{
                        fontSize: '0.875rem',
                        fontWeight: 400,
                        lineHeight: '1rem',
                        letterSpacing: '0em',
                        color: '#536580',
                        position: 'absolute',
                        bottom: 34
                      }}>Previous Step</Button>
                    </Grid>
                  }
                </Grid>
              </div>
            </form>
          )}
        </Formik>
      </div >
    );
  };

  return (
    <div>
      {(blockGroupFormMode === ADMIN_FORM_MODES.add || openOnlyNumbers) &&
        <OZDialog
          title="Add Block Numbers"
          hideActions={(showBlockNumberAddForm || openOnlyNumbers) ? true : false}
          saveButtonProps={openOnlyNumbers ? {} : {
            form: 'blockGroupForm',
            type: 'submit'
          }}
          open={openBlockAddForm}
          mode={blockGroupFormMode}
          onClose={openOnlyNumbers ? () => { setOpenOnlyNumbers(false); clearLocalStorageForBlockGroupForm();clearLocalStorageForBlockNumberForm(); uploadedNumbers = [] } : () => {
            closeBlockGroupForm(); clearLocalStorageForBlockGroupForm();clearLocalStorageForBlockNumberForm();
            ;
          }}
        >
          {openOnlyNumbers && clearLocalStorageForBlockGroupForm() && clearLocalStorageForBlockNumberForm()
          }
          {openOnlyNumbers ? 
            console.log("upload phone number comp need")
            // <UploadPhoneNumber data={blockGroupFormMainProps.data} />
            :
            <DataForm openAddBlockNumberDialog={openAddBlockNumberDialog} />}
        </OZDialog>
      }

      {(blockGroupFormMode === "View" || blockGroupFormMode === "Edit") &&
        <div>
          <OZDrawer
            open={openBlockAddForm}
            title={
              <div className={"oz-add-phone-number-form-drawer-header-container " + (blockGroupFormMode === "View" ? "oz-add-phone-number-form-drawer-header-view-container" : "oz-add-phone-number-form-drawer-header-edit-container")}>
                {blockGroupFormMode === "View" ?
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip title={blockGroupFormMainProps.data.blockedNumber}><div
                      className="oz-managenumbers-drawer-title-heading"
                      sx={{
                        fontWeight: 600,
                        fontSize: "1.125rem",
                        width: "11rem",
                        marginLeft: "10px",
                        marginTop: '1px'
                      }}
                      flexGrow={1}>
                      {blockGroupFormMainProps.data.blockedNumber}
                    </div>
                    </Tooltip>
                  </div> :
                  <Tooltip title={blockGroupFormMainProps.data.blockedNumber}><div
                    className="oz-managenumbers-drawer-title-heading"
                    sx={{
                      fontWeight: 600,
                      fontSize: "1.125rem",
                      width: "11rem",
                      marginLeft: "11px",
                      marginTop: '1px'
                    }}
                    flexGrow={1}>
                    {"Edit " + blockGroupFormMainProps.data.blockedNumber}
                  </div>
                  </Tooltip>
                }

              {(newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'Edit') || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'Full')) &&
              <>
                {blockGroupFormMode === "Edit" &&
                  <Typography
                    sx={{
                      fontSize: '0.875rem', marginLeft: "auto", fontWeight: 400, color: "#3D8BF8", cursor: "pointer"
                    }}
                    onClick={() => setBlockGroupFormModeState('View')}>
                    Discard
                  </Typography>
                }
              </>
              }
              </div>
            }
            onClose={closeBlockGroupForm}
            hideEditIcon={!(newAuthPages(NewAdminPages.MANAGE_NUMBERS) || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'Full') || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'Edit'))}
            onEdit={() => {
              setOpenEditDialog(true)
              // setBlockGroupFormModeState(ADMIN_FORM_MODES.edit)
            }}
            mode={blockGroupFormMode} >
            {blockGroupFormMode === ADMIN_FORM_MODES.view ?
              <ViewGroupNumberForm openAddBlockNumberDialog={openAddBlockNumberDialog} {...blockGroupFormMainProps} />
              :
              <DataForm openAddBlockNumberDialog={openAddBlockNumberDialog} data={blockGroupFormMainProps.data} />
            }
          </OZDrawer>
          {openEditDialog && (
            <div>
              {console.log("blockGroupFormMainProps", blockGroupFormMainProps.data)}
              <EditExpiryDateBlockGroupForm
                props={blockGroupFormMainProps.data}
                // update={onUpdateFunction}
                // onClose={closeEditBlockGroupForm}
                onClose={() => {
                  setOpenEditDialog(false)
                }}
                onCloseUpdate={()=>{
                  blockGroupFormMainProps.updateBlockGroup && blockGroupFormMainProps.updateBlockGroup({})
                  blockGroupFormMainProps.expUpdate && blockGroupFormMainProps.expUpdate(blockGroupFormMainProps.data.id)
                }}
              // update={blockGroupFormMainProps.data}
              />
            </div>
          )}
        </div>
      }
    </div >
  );
};

export default AddBlockGroupForm;