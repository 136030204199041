
import { getSettingsListByCode } from "../services/api-service";
import { getisSuperAdminTrue, getUserRoles } from "../services/auth-service";
import jwt from 'jwt-decode';

const leftFillNum = (num: number, targetLength: number): string => {
	return num.toString().padStart(targetLength, '0');
};
export const secondsToTime = (secondsNum: number): string => {
	if (!secondsNum || secondsNum <= 0) {
		return '00:00:00';
	}
	const hours = Math.floor(secondsNum / 3600);
	const minutes = Math.floor((secondsNum - hours * 3600) / 60);
	const seconds = secondsNum - hours * 3600 - minutes * 60;
	return `${leftFillNum(hours, 2)}:${leftFillNum(minutes, 2)}:${leftFillNum(seconds, 2)}`;
};

export const secondsToMinSec = (secondsNum: number): string => {
	if (!secondsNum || secondsNum <= 0) {
		return '00:00';
	}
	const minutes = Math.floor(secondsNum / 60);
	const seconds = secondsNum - minutes * 60;
	return `${leftFillNum(minutes, 2)}:${leftFillNum(seconds, 2)}`;
};

export const convertSecondsToTimeNew = (secondsNum: number) : string => {
	if (!secondsNum || secondsNum <= 0) {
        return '00:00';
    }

    const totalSeconds = Math.round(secondsNum);
    const minutes = Math.floor(totalSeconds / 60);
    const remainingSeconds = totalSeconds % 60;

    return `${leftFillNum(minutes, 2)}:${leftFillNum(remainingSeconds, 2)}`;
};

export const getTotalRowAndActualData = (data: any, key: string, multiple = false) => {
	const totalRow = multiple ? data.filter((item: any) => +item[key] === 0) ?? [] : data.find((item: any) => +item[key] === 0) ?? {};
	const actualData = data.filter((item: any) => item[key] !== '0');
	return { totalRow, actualData };
};
const getRowData = (thresholdList: any, row: any) => {
	const tempRow = { ...row };
	for (const threshold of thresholdList) {
		// console.log("row::::::::::::::::::::", threshold)
		if (!threshold.key || (threshold.key && (row[threshold.key] === threshold.value || row[threshold.key]?.value === threshold.value))) {
			for (const field of threshold.fields) {
				// console.log("field:::::::::::::::", field)
				// console.log("tempRow:::::::::::::::", tempRow)
				let fieldValue;
				if (tempRow[field.key]?.warning) {
					delete tempRow[field.key].warning;
				}
				if (field?.value?.length) {
					switch (field.condition) {
						case 'GREATER_THAN': {
							// let value = tempRow[field.key]?.value || tempRow[field.key] ;
							let value = tempRow[field.key]?.value || tempRow[field.key];
							// console.log("value:::::::::::::", value)
							// console.log("+field.value:::::::::::::", +field.value)
							// console.log("field.key:::::::::::::", field.key)
							if (field.key.toLowerCase().endsWith("time")) {
								fieldValue = secondsToTime(+field.value);
								String(value)
							} else {
								fieldValue = +field.value;
							}
							// console.log("final  value :::::::::::::", fieldValue)
							if (value >= fieldValue) {
								if (typeof tempRow[field.key] === 'object') {
									tempRow[field.key] = { ...tempRow[field.key], warning: 'warning' };
								} else {
									tempRow[field.key] = { value: tempRow[field.key], warning: 'warning' };
								}
							}
							break;
						}
						case 'LESS_THAN': {
							const value = tempRow[field.key]?.value || tempRow[field.key];
							if (+value <= +field.value) {
								tempRow[field.key] = { value: tempRow[field.key], warning: 'warning' };
							}
							break;
						}
						default: {
							const value = tempRow[field.key]?.value || tempRow[field.key];
							if (+value >= +field.value) {
								tempRow[field.key] = { value: tempRow[field.key], warning: 'warning' };
							}
							break;
						}
					}
				}
			}
		}

	}
	return tempRow;
}
export const mapRowDataWithThreshold = (thresholdList: any[], rowData: any[]) => {
	const finalRowData = [];
	if (!rowData.length) {
		// console.log("rowData", rowData)
		finalRowData.push(getRowData(thresholdList, rowData));
	} else {
		for (const row of rowData) {
			// console.log("rowData", rowData)
			finalRowData.push(getRowData(thresholdList, row));
		}
	}
	return finalRowData;
}

export const mapRowDataWithThresholdNew = (thresholdList: any[], rowData: any[]) => {
	const finalRowData = [];
	for (const row of rowData) {
		const tempRow = { ...row };
		for (const threshold of thresholdList) {
			if (threshold.label === "Organization Level") {
				if (threshold.isOrgSkill) {
					for (const orgField of threshold.fields) {
						if (orgField?.value?.length) {
							switch (orgField.condition) {
								case 'GREATER_THAN': {
									let orgNewvalue = tempRow[orgField.key]?.value || tempRow[orgField.key];
									if (+orgNewvalue >= +orgField.value) {
										if (typeof tempRow[orgField.key] === 'object') {
											tempRow[orgField.key] = { ...tempRow[orgField.key], warning: 'warning' };
										} else {
											tempRow[orgField.key] = { value: tempRow[orgField.key], warning: 'warning' };
										}
									}
									break;
								}
								case 'LESS_THAN': {
									const orgNewvalue = tempRow[orgField.key]?.value || tempRow[orgField.key];
									if (+orgNewvalue <= +orgField.value) {
										tempRow[orgField.key] = { value: tempRow[orgField.key], warning: 'warning' };
									}
									break;
								}
								default: {
									const orgNewvalue = tempRow[orgField.key]?.value || tempRow[orgField.key];
									if (+orgNewvalue >= +orgField.value) {
										tempRow[orgField.key] = { value: tempRow[orgField.key], warning: 'warning' };
									}
									break;
								}
							}
						}
					}
					if (threshold.isOrgSkill) {
						break;
					}
				}
			}
			else {
				if (!threshold.key || (threshold.key && (row[threshold.key] === threshold.value || row[threshold.key]?.value === threshold.value))) {
					for (const field of threshold.fields) {
						if (field?.value?.length) {
							switch (field.condition) {
								case 'GREATER_THAN': {
									let value = tempRow[field.key]?.value || tempRow[field.key];
									if (+value >= +field.value) {
										if (typeof tempRow[field.key] === 'object') {
											tempRow[field.key] = { ...tempRow[field.key], warning: 'warning' };
										} else {
											tempRow[field.key] = { value: tempRow[field.key], warning: 'warning' };
										}
									}
									break;
								}
								case 'LESS_THAN': {
									const value = tempRow[field.key]?.value || tempRow[field.key];
									if (+value <= +field.value) {
										tempRow[field.key] = { value: tempRow[field.key], warning: 'warning' };
									}
									break;
								}
								default: {
									const value = tempRow[field.key]?.value || tempRow[field.key];
									if (+value >= +field.value) {
										tempRow[field.key] = { value: tempRow[field.key], warning: 'warning' };
									}
									break;
								}
							}
						}
					}
				}
			}
		}
		finalRowData.push(tempRow);
	}
	return finalRowData;
}

// export function checkRoles(roleName: string) {
// 	let isFound = false;
// 	let roles = getUserRoles();
// 	if (roles != null && roles.length !== 0) {
// 		roles.forEach((role: any) => {
// 			if (role.name === roleName) {
// 				isFound = true;
// 				return
// 			}
// 		});
// 	}
// 	return isFound
// };

export function checkRoles(roleName: string): boolean {
	let roles = getUserRoles();
	if (roles && roles.length > 0) {
		return roles.some((role: any) => role.name === roleName);
	}
	return false;
}
export function checkDataAccessFilters(): boolean {
	const roleCheck = checkRoles("ROLE_DATA_ACCESS");
	const isAdmin = localStorage.getItem('isAdmin') === 'true';
	return roleCheck && !isAdmin;
}

export function checkPaginationForLiveReports(): boolean {//for OZTable condition  whetehr it is sub-admin or admin
	const roleCheck = checkRoles("ROLE_DATA_ACCESS");
	const isAdmin = localStorage.getItem('isAdmin') === 'true';

	if(isAdmin && roleCheck) {
		return true;
	}
	if(!isAdmin) {
		return checkDataAccessFilters();
	}
	return false;
}

export const getFilterType = (disabledFilters: string[], defaultFilter: number): number => {
    const valueMap: { [key: string]: number } = {
        campaignName: 1,
        skill: 2,
        agent: 8,
    };

    // If no filters are disabled, return 1
    if (disabledFilters.length === 0) {
        return defaultFilter;
    }

    // If all filters are present, return 1
    if (disabledFilters.length === 3) {
        return 1;
    }

    // Determine the CallValue based on missing elements
    const missingValues = Object.keys(valueMap).filter(key => !disabledFilters.includes(key));
    if (missingValues.length > 0) {
        return valueMap[missingValues[0]]; // Return the value of the first missing element
    }

    // Default case (should not be reached)
    return 1;
};


export const determineFilterType = (filtersArray: string[]): any => {
    if (filtersArray[0] === 'Campaign') {
        return 1;
    } else if (filtersArray[0] === 'Skill') {
        return 2;
    } else if (filtersArray[0] === 'Group') {
        return 3;
    }
    return 1;
};

export async function checkSettings(settingName: string): Promise<boolean> {
	try {
		const resp = await getSettingsListByCode({ isAdmin: "true", settingsCode: settingName });
		if (resp?.Data?.length > 0 || resp?.Data !== null) {
			const data = resp?.Data[0];
			if (data?.ParameterValue !== null) {
				if (data?.ParameterValue === "true" || data?.ParameterValue === true) {
					return true;
				} else if (data?.ParameterValue === "false" || data?.ParameterValue === false) {
					return false;
				}
			} else if (data?.ParameterValue === null) {
				if (data?.DefaultValue === "true" || data?.DefaultValue === true) {
					return true;
				} else if (data?.DefaultValue === "false" || data?.DefaultValue === false) {
					return false;
				}
			}
		} else {
			// console.log("Empty data for setting:", settingName);
		}
	} catch (e) {
		// console.log("Error occurred while calling the settings:", e);
	}
	// console.log("Setting not found:", settingName);
	return false;
}

export function checkIsAdminTrue() {
	let isFound = false;
	let jwtToken = getisSuperAdminTrue()
	const tokenJwt: any = jwt(jwtToken);
	if (tokenJwt.isSupperAdmin === true) {
		isFound = true;
	}
	return isFound
}


// export const createLiveFilterIdss = (selectedFilters: any) => {
// 	let filterIds: any = {};
// 	Object.keys(selectedFilters).forEach((key) => {
// 		if (typeof selectedFilters[key] === 'string') {
// 			if (selectedFilters[key] === 'All') {
// 				filterIds[key] = '0';
// 			} else {
// 				filterIds[key] = selectedFilters[key];
// 			}
// 		} else if (key === 'modeType' && typeof selectedFilters[key] === 'object') {
// 			if (selectedFilters[key]?.id === 'All') {
// 				filterIds[key] = '100';
// 				console.log("i am here in if")
// 			} else if (selectedFilters[key]?.id === 'Manual') {
// 				filterIds[key] = '1';
// 			}
// 			else if (selectedFilters[key]?.id === 'Inbound') {
// 				filterIds[key] = '2';
// 			}
// 			else if (selectedFilters[key]?.id === 'Progressive') {
// 				filterIds[key] = '3';
// 			}
// 			else if (selectedFilters[key]?.id === 'Preview') {
// 				filterIds[key] = '4';
// 			}
// 			else if (selectedFilters[key]?.id === 'Blended') {
// 				filterIds[key] = '5';
// 			}
// 			else if (selectedFilters[key]?.id === 'Chat') {
// 				filterIds[key] = '8';
// 			} else {
// 				filterIds[key] = selectedFilters[key].map((item: any) => item.id).join(',');
// 			}
// 		}
// 		else if (Array.isArray(selectedFilters[key]) && selectedFilters[key].length > 0) {
// 			if (selectedFilters[key][0].id === 'All') {
// 				filterIds[key] = '0';
// 			} else {
// 				filterIds[key] = selectedFilters[key].map((item: any) => item.id).join(',');
// 			}
// 		}
// 		else if (typeof selectedFilters[key] === 'object') {
// 			if (key === 'callType' && selectedFilters[key].name === 'All') {
// 				filterIds[0] = ''; // Append filterIds[0] = "" for "callType"
// 			} else {
// 				filterIds[key] = selectedFilters[key].id;
// 			}
// 		}

// 	});
// 	return filterIds;

// };

export const createLiveFilterIdss = (selectedFilters: any) => { //this is modified code for the Live Filters when passing the value All
	let filterIds: any = {};
	Object.keys(selectedFilters).forEach((key) => {
		if (typeof selectedFilters[key] === 'string') {
			if (selectedFilters[key] === 'All') {
				filterIds[key] = '0';
			} else {
				filterIds[key] = selectedFilters[key];
			}
		} else if (key === 'modeType' && typeof selectedFilters[key] === 'object') {
			if (selectedFilters[key]?.id === 'All') {
				filterIds[key] = '100';
				// console.log("i am here in if")
			} else if (selectedFilters[key]?.id === 'Manual') {
				filterIds[key] = '1';
			}
			else if (selectedFilters[key]?.id === 'Inbound') {
				filterIds[key] = '2';
			}
			else if (selectedFilters[key]?.id === 'Progressive') {
				filterIds[key] = '3';
			}
			else if (selectedFilters[key]?.id === 'Preview') {
				filterIds[key] = '4';
			}
			else if (selectedFilters[key]?.id === 'Blended') {
				filterIds[key] = '5';
			}
			else if (selectedFilters[key]?.id === 'Chat') {
				filterIds[key] = '8';
			} else {
				filterIds[key] = selectedFilters[key].map((item: any) => item.id).join(',');
			}
		}
		else if (Array.isArray(selectedFilters[key]) && selectedFilters[key].length > 0) {
			if (key === 'channelType' && selectedFilters[key][0].id === 'All') {
				filterIds[key] = ''; // Append filterIds[0] = "" for "callType"
			}  else if(selectedFilters[key][0].id === 'All' && key !== 'channelType') {
				filterIds[key] = '0';
			}else {
				filterIds[key] = selectedFilters[key].map((item: any) => item.id).join(',');
			}
		}
		else if (typeof selectedFilters[key] === 'object') {
			if (key === 'callType' && selectedFilters[key].name === 'All') {
				filterIds[0] = ''; // Append filterIds[0] = "" for "callType"
			}  else if(selectedFilters[key].name === 'All' && key === 'callEvent') {
				filterIds[key] = '';
			}else if(selectedFilters[key].name === 'All' && key !== 'callType' && key !== 'callEvent' && key !== 'channelType') {
				// filterIds[key] = selectedFilters[key].id;
				filterIds[key] = '0';
			}else{
				filterIds[key] = selectedFilters[key].id;
			}
		}

	});
	return filterIds;

};

export const createLiveFilterIds = (selectedFilters: any) => {
	let filterIds: any = {};
	Object.keys(selectedFilters).forEach((key) => {
		if (typeof selectedFilters[key] === 'string') {
			if (selectedFilters[key] === 'All') {
				filterIds[key] = 0;
			} else {
				filterIds[key] = selectedFilters[key];
			}
		} else if (Array.isArray(selectedFilters[key]) && selectedFilters[key].length > 0) {
			if (selectedFilters[key][0].id === 'All') {
				filterIds[key] = 0;
			} else {
				filterIds[key] = selectedFilters[key].map((item: any) => item.id).join(',');
			}
		} else if (typeof selectedFilters[key] === 'object') {
			if (selectedFilters[key].name === 'All') {
				filterIds[key] = 0;
			} else {
				filterIds[key] = selectedFilters[key].id;
			}
		}
	});
	return filterIds;
};

export const createHistoricFilterIds = (selectedFilters: any) => {
	let filterIds: any = {};
	if (selectedFilters) {
		Object.keys(selectedFilters).forEach((key) => {
			const value = selectedFilters[key];
			if (Array.isArray(value)) {
				filterIds[key] = value.map((item: any) => item.id).join(',');
			} else if (typeof value === 'object') {
				filterIds[key] = value.id;
			} else {
				filterIds[key] = value;
			}
		});
	}
	return filterIds;
};

const FILTER_ID_LABELS: { [key: string]: string } = {
	groupNames: 'groupIds',
	phoneNumbers: 'blockNumberIds'
}

export const createFilterIds = (selectedFilters: any) => {
	let filterIds: any = {};
	if (selectedFilters) {
	  Object.keys(selectedFilters).forEach((key) => {
		const newKey = FILTER_ID_LABELS[key] || key;

		const value = selectedFilters[key];
		if (Array.isArray(value)) {
		  filterIds[newKey] = value.map((item: any) => item.id).join(',');
		} else if (typeof value === 'object' && value !== null) {
		  filterIds[newKey] = value.id;
		} else {
		  filterIds[newKey] = value;
		}
	  });
	}
	return filterIds;
}; 

export const withoutPercentageChar = (value: any) => {
	const convertedValue = value.slice(0, -2);
	return convertedValue;
};

export const percentageCal = (value: any, total: any) => {
	if (value === 0 || total === 0) {
		return 0;
	  }
	  return (value / total) * 100;
}

export const getRandomColor = () => {
	return `rgb(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.4)`;
};

export const isSubUser = (): boolean => {
	return localStorage.getItem('isAdmin') === 'false';
};