import { ReactComponent as FilterAltOutlinedIcon } from '../../../static/icons/filter.svg';
import { ReactComponent as RefreshOutlinedIcon } from '../../../static/icons/refresh.svg';
import { Box, Card, Grid, LinearProgress, Popover, Stack, Theme, Badge } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { makeStyles } from '@mui/styles';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as React from 'react';
import { getFilters, getSLAData, slaThresholdApi, getWidgetConfig } from '../../../services/api-service';
import { getLastFetchedDetail } from '../../../services/application-service';
import FilterDrawer from '../../FilterDrawer/FilterDrawer';
import WidgetDownload from '../../Widget-Download/Widget-Download';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import '../SLA/SLA.css';
import { WIDGET_REFRESH_INTERVAL } from '../../../utils/constants';
import ThresholdDrawer from '../../ThresholdDrawer/ThresholdDrawer';
import WidgetGraph from '../../Widget-Graph/Widget-Graph';
import { checkDataAccessFilters, determineFilterType } from '../../../utils/util';
import useChartResize from '../../../hooks/useChartResize.jsx';
// Highcharts.seriesTypes.area.prototype.drawLegendSymbol =
//      Highcharts.seriesTypes.line.prototype.drawLegendSymbol;

const defaultFilters = [
	{ id: 1, label: 'General', color: '#EE4060', target: '80%' },
	{ id: 2, label: 'Canada Sales', color: '#EEBE40', target: '78%' },
	{ id: 3, label: 'Support', color: '#B0CFA5', target: '88%' },
	{ id: 4, label: 'English Sales', color: '#53B9ED', target: '90%' },
	{ id: 5, label: 'Premium Plus', color: '#B2A1F9', target: '72%' }
];
const useStyles: any = makeStyles((theme: Theme) => ({
	boundButton: {
		padding: '5px !important'
	},
	filterItem: {
		fontSize: 12,
		height: 'auto',
		lineHeight: '14px',
		borderRadius: 20,
		background: 'rgba(0, 142, 255, 0.1)',
		border: '1px solid #008eff',
		padding: `4px ${theme.spacing(2)}`
	},
	itemIndicator: {
		width: 5,
		height: 5,
		borderRadius: 1,
		display: 'inline-block',
		position: 'relative',
		top: '-2px',
		marginRight: 4
	},
	filterItemText: {
		margin: 0,
		color: '#536580'
	},
	filterItemSubText: {
		color: '#99A0A8'
	},
	chipClose: {
		display: 'flex'
	},
	popoverContent: {
		minWidth: 370,
		fontSize: '12px',
		lineHeight: 2
	}
}));

const getThresholdFields = (slaDuration: string = '', slaThreshold: string = '') => [
	{ label: 'Target%', value: slaThreshold, key: 'slaThreshold', condition: 'GREATER_THAN' },
	{ label: 'AnsweringTime(Sec)', value: slaDuration, key: 'slaDuration', condition: 'GREATER_THAN' }
];

let interval: any;

function SLA(props: any) {
	const graphPointData = [
		{ label: 'SLA %', key: 'y', withProgress: true },
		{ label: 'SLA % Same day Last week', key: 'HistoricSLAPercentage', withProgress: true },
		// { label: 'HistoricSLAPercentage', key: 'sameDayLastWeek', withProgress: true },
		{ label: 'AHT', key: 'AverageHandlingTime', withProgress: false },
		{ label: 'ASA', key: 'AverageSpeedToAnswer', withProgress: false },
		{ label: 'Total Calls', key: 'TotalCalls', withProgress: false },
		{ label: 'Answered', key: 'AnsweredCalls', withProgress: false },
		{ label: 'Unanswered', key: 'UnansweredCalls', withProgress: false }
	];

	const { useState, useRef } = React;
	const [isDrawerOpen, setIsDrawerOpen] = useState<boolean | undefined>(false);
	const [responseLength, setresponseLength] = useState<Number>(0);
	const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
	const [selectedPoint, setSelectedPoint] = useState<any>({});
	const [graphData, setGraphData] = useState<any>([]);
	const [slaData, setSlaData] = useState<any>([]);
	const [graphRefPoint, setGraphRefPoint] = useState<any>(null);
	const [bound, setBound] = useState<string | null>('voice');
	const [liveValue, setLiveValue] = useState<number>(0);
	const [targetValue, setTargetValue] = useState<number>(0);
	const [slaFilter, setSlaFilter] = useState<any>(props?.selectedFilters?.filters || []);
	const [allowedFilters, setAllowedFilters] = useState<string[]>(['Skill']);
	const [filterType, setFilterType] = useState<any>(props?.selectedFilters?.filterSection?.id || 2);
	const [filterSection, setFilterSection] = useState<any>(props?.selectedFilters?.filterSection || {});
	const downloadContent = useRef<null | HTMLDivElement>(null);
	const [isThresholdDrawerOpen, setIsThresholdDrawerOpen] = useState<boolean | undefined>(false);
	const [thresholdItems, setThresholdItems] = useState<Array<any>>([]);
	const [lastFetchedTime, setLastFetchedTime] = useState<any>('00:00');
	const { chartElementRef, chartContainerRef, chartDimensions, redrawChart } = useChartResize();

	// const chartElement: any = useRef(null);
	const classes = useStyles();

	const toggleThresholdDrawer = async (isOpen: boolean, data: any = null) => {
		setIsThresholdDrawerOpen(isOpen);
		if (data) {
			const transformedData = data.map((item: any) => {
				const slaDuration = item.fields.find((field: any) => field.key === 'slaDuration').value;
				const slaThreshold = item.fields.find((field: any) => field.key === 'slaThreshold').value;

				if (slaDuration === item.slaDuration && slaThreshold === item.slaThreshold) {
					return;
				}

				return {
					...item,
					slaDuration,
					slaThreshold,
					fields: undefined,
					sequenceId: item.sequenceId ? item.sequenceId : null,
					isActive: item.label === 'OrganizationalSLA' ? 1 : 0
				};
			});
			const filteredData = transformedData.filter(Boolean);

			const desiredFormat = JSON.stringify(filteredData);

			await slaThresholdApi('update', desiredFormat);
			retriveSlaGraphData();
			updateThresholdItems();
		}
	};

	const clearFilter = () => {
		setSlaFilter([]);
		props?.handleFilterChange?.([], filterSection, props?.widgetId)
	};

	const handleFilterDelete = (id: Number) => {
		const newFilter = slaFilter.filter((item: any) => item.id !== id);
		setSlaFilter(newFilter);
		props?.handleFilterChange?.(newFilter, filterSection, props?.widgetId)
	};

	const handleFilterExistingData = (filteredData: any) => {
		setSlaFilter(filteredData);
		props?.handleFilterChange?.(filteredData, filterSection, props?.widgetId);
	};

	const getPopoverContent = () => {
		return (
			<Box sx={{ p: 2 }} className={classes.popoverContent}>
				<b>SLA breakdown</b>
				<div>
					{graphPointData.map((item: any, idx: number) => (
						<Grid key={idx} container direction="row" justifyContent="space-between" alignItems="center">
							<Grid item xs={7}>
								{item.label}
							</Grid>
							{item.withProgress ? (
								<Grid item xs={4}>
									<Grid container direction="row" justifyContent="space-between" alignItems="center">
										<Grid item>
											<span>
												<b>{selectedPoint?.options?.[item.key] || 0}</b>
											</span>
										</Grid>
										<Grid item xs={9}>
											<LinearProgress variant="determinate" value={selectedPoint?.options?.[item.key] || 0} />
										</Grid>
									</Grid>
								</Grid>
							) : (
								<span>
									<b>{selectedPoint?.options?.[item.key] || 0}</b>
								</span>
							)}
						</Grid>
					))}
				</div>
			</Box>
		);
	};
	const filterItems = slaFilter.map((item: any, index: number) => (
		<Box key={index} className={classes.filterItem}>
			<Box flexDirection={"row"} display={"flex"} alignItems="center" gap={1} maxHeight={'40px'} minWidth={'120px'} justifyContent={"space-between"}>
				<Box display={"flex"} flexDirection={"column"} alignItems="center" py={1} gap={1} minWidth={'100px'}>
					<p className={classes.filterItemText}>
						<span className={classes.itemIndicator} style={{ background: item.color }}></span>
						{item.label}
					</p>
					<span className={classes.filterItemSubText}>Target: </span>
					<b>{item.target}</b>
				</Box>
				<Box className={classes.chipClose}>
					<CloseOutlinedIcon onClick={() => handleFilterDelete(item.id)} color="primary" style={{ cursor: 'pointer', fontSize: '14px' }} />
				</Box>
			</Box>
		</Box >
	));

	const options = {
		chart: {
			type: 'line',
			height: chartDimensions.height,
			width: chartDimensions.width * 1.06,
		},
		credits: { enabled: false },
		title: { text: '' },
		xAxis: {
			title: {
				text: 'Time ➡',
				style: { fontWeight: 'bold' }
			},
			categories: [
				'00:00',
				'01:00',
				'02:00',
				'03:00',
				'04:00',
				'05:00',
				'06:00',
				'07:00',
				'08:00',
				'09:00',
				'10:00',
				'11:00',
				'12:00',
				'13:00',
				'14:00',
				'15:00',
				'16:00',
				'17:00',
				'18:00',
				'19:00',
				'20:00',
				'21:00',
				'22:00',
				'23:00'
			],
			plotLines: [
				{
					color: '#D42015',
					width: 1,
					value: liveValue,
					dashStyle: 'dash'
				}
			]
		},
		yAxis: {
			title: {
				text: ''
			},
			plotLines: [
				{
					color: '#008DFF',
					width: 2,
					value: targetValue,
					dashStyle: 'dash'
				}
			],
			gridLineDashStyle: 'dash',
			minorGridLineDashStyle: 'dash',
			minorTickInterval: 'auto',
			minorTickWidth: 0
		},
		legend: {
			layout: 'horizontal',
			align: 'right',
			verticalAlign: 'top',
			itemMarginTop: 10,
			symbolWidth: 50,
			enabled: false
		},
		tooltip: {
			formatter(this: any): any {
				return `${this.y}%`;
			}
		},
		plotOptions: {
			line: {
				marker: {
					enabled: true
				}
			},
			series: {
				lineWidth: 1.5,
				cursor: 'pointer',
				fillColor: {
					linearGradient: [0, 0, 0, 250],
					stops: [
						[0, Highcharts?.getOptions()?.colors?.[0]],
						[
							1,
							Highcharts?.color(Highcharts?.getOptions()?.colors?.[0] || '')
								?.setOpacity(0)
								?.get('rgba')
						]
					]
				},
				point: {
					events: {
						click: function ({ point }: any) {
							handlePointClick(point);
						}
					}
				}
			}
		},
		series: graphData
	};

	const retriveSlaGraphData = async (filters = '0', type = '2') => {
		const apiResponse = await getSLAData({ filters, type });
		setSlaData(apiResponse);
		const sortedResponse = [...apiResponse];
		sortedResponse.sort((a, b) => (+a.CallHour > +b.CallHour ? 1 : +b.CallHour > +a.CallHour ? -1 : 0));
		const finalData = filters === '0' ? sortedResponse.filter((item) => +item.SkillId === 0) : sortedResponse;
		const slaPercentage = finalData.map((item) => ({ hour: +item.CallHour, y: +item.SLAPercentage, ...item }));
		const latestDataPoint: any = slaPercentage.length ? slaPercentage[slaPercentage.length - 1] : {};
		const { hour = 0, percentage = 0 } = latestDataPoint;
		setLiveValue(hour);
		setTargetValue(percentage);
		const populatedData = new Array(hour + 1).fill({ y: 0 });
		for (const item of slaPercentage) {
			populatedData[item.hour] = item;
		}
		const data = [
			{
				name: 'SLA %',
				data: populatedData,
				type: 'area'
			},
			{
				name: 'Target',
				color: '#008DFF',
				dashStyle: 'dash'
			},
			{
				name: 'Live',
				color: '#D42015',
				dashStyle: 'dash'
			}
		];
		setGraphData(data);
		setLastFetchedTime(getLastFetchedDetail());
		// chartElement?.current?.chart?.redraw();
		redrawChart();
	};

	const handleOnPopoverClose = () => {
		setGraphRefPoint(null);
		setSelectedPoint({});
		setIsPopoverOpen(false);
	};

	const handlePointClick = async (point: any) => {
		const filters = slaFilter.length ? slaFilter.map((item: any) => item.id).join(',') : '0';
		const historicData = await getSLAData({ filters, type: filterType, dateType: '1' });
		const currentPointData = historicData.find((item: any) => item.CallHour === point.options.CallHour);
		const updatedPoint = { ...point };
		updatedPoint.options.HistoricSLAPercentage = currentPointData?.HistoricSLAPercentage || 0;
		setSelectedPoint(point);
		setGraphRefPoint(point?.graphic?.element);
		setIsPopoverOpen(true);
	};

	const handleBound = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
		setBound(newAlignment);
	};

	const toggleDrawer = (isOpen: boolean) => {
		setIsDrawerOpen(isOpen);
	};

	const toggleRefresh = () => {
		const filterIds = slaFilter.length ? slaFilter.map((item: any) => item.id).join(',') : '0';
		retriveSlaGraphData(filterIds, filterType);

		// lastFetchedUpdate();
	};

	const handleDownload = (type: string) => {
		console.log(type);
	};

	const handleApplyFilters = ({ selectedFilters, filterType: newFilterType, filterSection: newfilterSection }: any) => {
		setSlaFilter(selectedFilters);
		setFilterType(newFilterType);
		setFilterSection(newfilterSection);
		props?.handleFilterChange?.(selectedFilters, newfilterSection, props?.widgetId)
		toggleDrawer(false);
	};

	const updateThresholdItems = async () => {
		let newThresholdItems = [];
		const response: any = await getFilters('Skill');

		const slaThresholdData = await slaThresholdApi('list');
		const orgThreshold = slaThresholdData.find((item: any) => item.SkillId === '0') || {};

		newThresholdItems.push({ label: 'Organization Level', key: 'SkillName', value: '', fields: getThresholdFields(orgThreshold?.slaDuration, orgThreshold?.slaThreshold) });

		for (const row of response) {
			const skillThreshold = slaThresholdData.find((item: any) => item.skillId === Number(row.Id)) || { slaDuration: 0, slaThreshold: 0 };
			newThresholdItems.push({
				...skillThreshold,
				label: row.Name,
				key: 'SkillName',
				value: row.Name,
				fields: getThresholdFields(skillThreshold.slaDuration, skillThreshold.slaThreshold)
			});
		}
		setThresholdItems(newThresholdItems);
	};

	React.useEffect(() => {
		updateThresholdItems();
	}, []);

	React.useEffect(() => {
		const fetchWidgetConfig = async () => {
			try {
				if (checkDataAccessFilters()) {
					let widgetConfig = await getWidgetConfig('101', 'widget');
					if (widgetConfig) {
						if (typeof widgetConfig === 'string') {
							widgetConfig = JSON.parse(widgetConfig);
						}
						// Check the condition and set the appropriate filters
						const filters = widgetConfig.data && checkDataAccessFilters()
							? widgetConfig.data
							: widgetConfig.config;

						if (filters) {
							const filtersArray = filters.split(',');
							setAllowedFilters(filtersArray);
							const determinedFilterType = determineFilterType(filtersArray);
							if (determinedFilterType !== undefined) {
								setFilterType(determinedFilterType);
							}
						}
					}
				}
			} catch (error) {
				console.error('Error fetching widget config:', error);
			}
		};
		fetchWidgetConfig();
	}, []);

	// React.useEffect(() => {
	// 	const resizeListener = () => {
	// 		chartElement?.current?.chart?.reflow();
	// 	};
	// 	window.addEventListener('widgetResize', resizeListener);
	// 	window.addEventListener('resize', resizeListener);
	// 	return () => {
	// 		clearInterval(interval);
	// 		window.removeEventListener('widgetResize', resizeListener);
	// 	};
	// }, []);

	React.useEffect(() => {
		// const filterIds = (agentListFilter.length && !(responseLength === agentListFilter.length)) ? agentListFilter.map((item: any) => item.id).join(',') : '0';
		const filterIds = (slaFilter.length && !(responseLength === slaFilter.length)) ? slaFilter.map((item: any) => item.id).join(',') : '0';
		retriveSlaGraphData(filterIds, filterType);
		if (interval) {
			clearInterval(interval);
		}
		// interval = setInterval(() => retriveSlaGraphData(filterIds, filterType), WIDGET_REFRESH_INTERVAL.SLA);
	}, [slaFilter]);

	return (
		<>
			<Card ref={downloadContent} className="customCard">
				<div className="card-header">
					<div className="card-title">
						SLA% <div className="card-title-badge">Live</div>
						<div className="refresh-btn card-icon" onClick={() => toggleRefresh()}>
							<RefreshOutlinedIcon fontSize="small" style={{ color: '#536580' }} />
						</div>
						<span className="last-fetched-time">Last fetched at: {lastFetchedTime} </span>
					</div>
					<div className="card-actions">
						<ToggleButtonGroup value={bound} disabled color="primary" exclusive aria-label="bound">
							<ToggleButton value="voice" disabled className={classes.boundButton} aria-label="voice">
								<span>Voice</span>
							</ToggleButton>
							{/* <ToggleButton value="outbound" className={classes.boundButton} aria-label="outbound">
								<span>Outbound</span>
							</ToggleButton> */}
						</ToggleButtonGroup>
						<WidgetDownload element={downloadContent} handleDownload={handleDownload} name={'SLA%'}></WidgetDownload>
						<div className="card-icon" onClick={() => toggleDrawer(true)}>
							<Badge badgeContent={slaFilter.length} color="primary">
								<FilterAltOutlinedIcon fontSize="small" style={{ color: '#536580' }} />
							</Badge>
						</div>
						<div onClick={() => toggleThresholdDrawer(true)}>
							<WidgetGraph></WidgetGraph>
						</div>
					</div>
				</div>
				<Box sx={{ width: '100%', height: '100%', display: "flex", flexDirection: "column", gap: 1 }}>
					<Box sx={{ pt: 2, pl: 2 }}>
						{slaFilter.length > 0 && (
							<div className="filter-outer filter-items-spacing">
								<div className="filter-inner" style={{ width: "calc(100% - 400px)", overflowX: "auto" }}>
									<div className="filter-container">
										<Stack direction="row" spacing={1}>
											{filterItems}
										</Stack>
									</div>
								</div>
								<div className="filter-remove" onClick={clearFilter}>
									Remove
								</div>
								<div className="widget-legend" style={{ minWidth: "200px" }}>
									<ul>
										<li>
											<img src={`${process.env.PUBLIC_URL}/icons/SLA-legend1.svg`} alt="SLA"></img> SLA %
										</li>
										<li>
											<img src={`${process.env.PUBLIC_URL}/icons/SLA-legend2.svg`} alt="Target"></img> Target
										</li>
										<li>
											<img src={`${process.env.PUBLIC_URL}/icons/SLA-legend3.svg`} alt="Live"></img> Live
										</li>
									</ul>
								</div>
							</div>
						)}
					</Box>
					<Box sx={{
						width: '100%',
						height: slaFilter.length > 0 ? 'calc(100% - 112px)' : 'calc(100% - 40px)',
					}}
						ref={chartContainerRef}>
						<HighchartsReact ref={chartElementRef} highcharts={Highcharts} options={options} />
					</Box>
				</Box>
			</Card>
			<FilterDrawer isOpen={isDrawerOpen} allowedFilters={allowedFilters} toggleDrawer={toggleDrawer} applyFilters={handleApplyFilters} existingSelectedFilters={slaFilter} setResponseLength={setresponseLength} existingFilterCategory={filterType} applyFilterOnExistingData={handleFilterExistingData} />
			{/* <FilterDrawer isOpen={isDrawerOpen} allowedFilters={['Skill']} toggleDrawer={toggleDrawer} applyFilters={handleApplyFilters} existingSelectedFilters={slaFilter} setResponseLength={setresponseLength} existingFilterCategory={filterType} applyFilterOnExistingData={handleFilterExistingData}/> */}
			<ThresholdDrawer isOpen={isThresholdDrawerOpen} title={'SLA'} toggleDrawer={toggleThresholdDrawer} thresholdItems={thresholdItems} />
			<Popover
				open={isPopoverOpen}
				onClose={handleOnPopoverClose}
				anchorReference="anchorEl"
				anchorEl={graphRefPoint}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
			>
				{getPopoverContent()}
			</Popover>
		</>
	);
}
export default SLA;
